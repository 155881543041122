import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'kt-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.scss']
})
export class PreciosComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  faCoffee = faCoffee;
  form_erros: boolean;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder) {
    this.form_erros = false;
  }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      pass: ['', [Validators.required]],
      confPass: ['', [Validators.required]],
      civil_status: [0],
      situacion: [0],
      address: ['', [Validators.required]],
      nie_passport: ['', [Validators.required]],
      location: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      i_have_signature: [''],
    });
  }
  ngOnDestroy() {

  }

  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  creaUsuario() {
    if (this.registerForm.value.pass === this.registerForm.value.confPass && this.registerForm.valid) {
      this.api.willianApi({ accion: 'RegistrarUsuario', argumentos: this.registerForm.value }).subscribe(res => {
        this.router.navigate(['/login']);
      }, error => {
        console.log(error);
      });
    } else {
      this.form_erros = true;
      return;
    }
  }

}
