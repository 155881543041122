import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
// Services
import { HeaderService } from '../../../services/header/header.service';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  nombre:string;
  constructor(public header: HeaderService, private router: Router, private cdRef: ChangeDetectorRef, private loginService: LoginService) {
    if(this.loginService.devolver){
      this.nombre=localStorage.getItem('nombre');
    }else{
      this.nombre='';
    }
  }

  ngOnInit() {
    $( document ).ready(function() {
      $('.navbar-toggler').on('click', function() {
        $(this).toggleClass( 'close-menu' );
      });
      $('.nav-item').on('click', function() {
        $('.navbar-toggler').toggleClass( 'close-menu' );
      });
    });
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 100) {
        $('.flecha-scroll').fadeOut(1000);
        $('.nav-for-desktop').addClass("bg-nav");
      }
      else {
        $('.flecha-scroll').fadeIn(1000);
        $('.nav-for-desktop').removeClass("bg-nav");
      }
    });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

}
