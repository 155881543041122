import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api/api.service.js';
import { DataService } from '../../services/data/data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
	selector: 'kt-pago',
	templateUrl: './pago.component.html',
	styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {

	url: SafeResourceUrl
	iframe: boolean = false

	msgError: string

	modelo_pago: string;
	precio: string;

	constructor(
		private api: ApiService,
		private cd: ChangeDetectorRef,
		private dataService: DataService,
		private fb: FormBuilder,
		private sanitizer: DomSanitizer
	) {	}

	ngOnInit() {
		// Recoge la información y pásale el identificador, el dinero sin decimales y el email del usuario
		// El estado del pago se cambiará desde el servidor
		// Mira en Filezilla en la ruta /back/pagomyspanish/test/php/HPP/card-payment/pay-by-link/response.php
		// Ahí se recogen los resultados del pago, obtendrás el estado del pago y el correo y esas cosas.

		// El pago en localhost no funciona, debes probarlo desde myspanishtaxes.com
		// Ahora mismo apunta a pruebas el pago, las tarjetas para que pruebes están en este enlace:
		// https://desarrolladores.addonpayments.com/#!/technical-resources/test-card

		/* const orderId = (Math.floor(Math.random() * 1000) + 1) + '-Pruebas';
		const amount = this.precio_final; // No poner decimales: amount = 56420 serían 564.20 €
		const email = localStorage.getItem('email');//localStorage.getItem('email');
		const supp = localStorage.getItem('pendiente'); */

		this.api.willianApi(
			{
				modelo: "relaciones_modelos",
				accion: "obtenerDatosPago",
				argumentos: localStorage.getItem('pendiente')
			}
		).subscribe(res => {
			this.modelo_pago = res['respuesta']['model_code'];
			this.precio = res['respuesta']['importe'];

			const orderId = (Math.floor(Math.random() * 1000) + 1) + '-Pruebas';
			const amount = parseInt(res['respuesta']['importe']) * 100; // No poner decimales: amount = 56420 serían 564.20 €
			const email = localStorage.getItem('email');
			const supp = localStorage.getItem('pendiente');
			this.generateLink(orderId, amount, email, supp);
		});
	}
	
	generateLink(orderId: string, amount: number, email: string, supp: string) {
		this.api.generateLink(
			orderId, amount, email, supp
		).subscribe(
			res => {
				const url = res.link;
				this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
				this.iframe = true;
				this.cd.detectChanges();
			},
			err => {
				const message = err.error ? err.error.message : err.message;
				this.msgError = 'An error has been produced :(' + (message || '');

				this.cd.detectChanges();
			}
		);
	}

	/* esto es para obtener los datos del pago */


}
