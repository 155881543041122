import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'kt-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  registerForm: FormGroup;
  form_erros: boolean;
  paises: object[];

  constructor(private fb: FormBuilder, private api: ApiService, private router: Router) {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      pass: ['', [Validators.required]],
      confPass: ['', [Validators.required]],
      civil_status: [0],
      situacion: [0],
      address: ['', [Validators.required]],
      nie_passport: ['', [Validators.required]],
      location: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      i_have_signature: [''],
    });
    this.form_erros = false;
    this.paises = [];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
  }

  ngOnInit() {
  }

  creaUsuario() {
    if (this.registerForm.value.pass === this.registerForm.value.confPass && this.registerForm.valid) {
      this.api.willianApi({ accion: 'RegistrarUsuario', argumentos: this.registerForm.value }).subscribe(res => {
        this.router.navigate(['/activate-user']);
      }, error => {
        console.log(error);
      });
    } else {
      this.form_erros = true;
      return;
    }
  }

}
