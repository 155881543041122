
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})

export class ReviewsComponent implements OnInit, OnDestroy {

  myform: FormGroup;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.myform = this.formBuilder.group({
      name: [''],
      last_name_1: [''],
      last_name_2: [''],
      email: [''],
      phone_1: [''],
      phone_2: [''],
      civil_status: ['']
    });


  }

  ngOnDestroy() {

  }
  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  /* creaUsuario() {

    this.api.createData('clientes', this.myform.value).subscribe(res => {
    }, error => {
      console.log(error);
    });

    this.router.navigate(["/precios"]);

  } */


}

