// Angular
import { Component } from '@angular/core';

/**
 * Sample context menu dropdown
 */
@Component({
	selector: 'kt-context-menu2',
	templateUrl: './context-menu2.component.html',
	styleUrls: ['./context-menu2.component.scss']
})
export class ContextMenu2Component {

	btnExportar(event: Event) {
		var modelo = 210;
		var datos = "";
		switch (modelo) {
			case 720:
				//MODELO 720
				//registro 1

				//pos 1 - 1 Constante número '1'. 
				datos += "1";
				//pos 2 - 3 Constante ‘720’. 
				datos += "720";
				//pos 5 - 4 EJERCICIO. 
				datos += "2020";
				//pos 9 - 9 N.I.F. DEL DECLARANTE.
				datos += rellenar(9, " ", "48314376R", "I");
				//pos 18 - 40 APELLIDOS Y NOMBRE O RAZÓN SOCIAL DEL DECLARANTE.
				datos += rellenar(40, " ", "ISMAEL CAMPS TORTAJADA", "D");
				//pos 58 - 1 TIPO DE SOPORTE. 
				datos += "T";
				// pos 59-107 PERSONA CON QUIÉN RELACIONARSE. 
				//pos 59 - 8 TELÉFONO: Campo numérico de 9 posiciones. 
				datos += rellenar(9, "0", "680789023", "I");
				//pos 68 - 107 APELLIDOS Y NOMBRE.
				datos += rellenar(40, " ", "ISMAEL CAMPS TORTAJADA", "D");
				//pos 108 - 13 NÚMERO IDENTIFICATIVO DE LA DECLARACIÓN.numérico de 13 posiciones.
				datos += rellenar(13, " ", "1234567891234", "I");
				//pos 121 - 2 DECLARACIÓN COMPLEMENTARIA O SUSTITUTIVA.
				datos += rellenar(2, " ", "", "D");
				//pos 123 - 12 NÚMERO IDENTIFICATIVO DE LA DECLARACIÓNANTERIOR.
				datos += rellenar(12, "0", "", "I");
				//pos 136 - 9 NÚMERO TOTAL DE REGISTROS DECLARADOS. 
				datos += rellenar(12, "0", "000000001", "I");

				// 145 - 18 Alfanumérico SUMA TOTAL DE VALORACIÓN 1: SALDO O VALOR A 31 DE DICIEMBRE; SALDO O VALOR EN LA FECHA DE EXTINCIÓN; VALOR DE ADQUISICIÓN.
					//145 - 1 SIGNO
					datos += " ";
					//pos 146 - 15 Parte entera del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(15, "0", "1050", "I");
					//pos 161 - 2 Parte decimal del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(2, "0", "50", "I");

				// 163 - 18 SUMA TOTAL DE VALORACIÓN 2: IMPORTE O VALOR DE LA TRANSMISIÓN; SALDO MEDIO ÚLTIMO TRIMESTRE.
				//163 - 1 SIGNO
				datos += " ";
				//pos 164 - 15 Parte entera del importe, si no tiene contenido se consignará a ceros.
				datos += rellenar(15, "0", "1973", "I");
				//pos 179 - 2 Parte decimal del importe, si no tiene contenido se consignará a ceros.
				datos += rellenar(2, "0", "25", "I");

				//pos 136 - 320 BLANCOS.
				datos += rellenar(320, " ", "", "I");

				//registro 2
				datos += "\r\n";

				//pos 1 - 1 Constante número '2'.
				datos += "2";
				//pos 2 - 3 Constante ‘720’.
				datos += "720";
				//pos 5 - 4 EJERCICIO.
				datos += "2020";
				//pos 9 - 9 N.I.F. DEL DECLARANTE.
				datos += rellenar(9, " ", "48314376R", "I");
				//pos 18 - 9 N.I.F. DEL DECLARANTE.
				datos += rellenar(8, " ", "48314376R", "I");
				//pos 9 - 9 N.I.F. DEL REPRESENTANTE LEGAL.
				datos += rellenar(9, "0", "B46175112", "I");
				//pos 36 - 40 APELLIDOS Y NOMBRE O RAZÓN SOCIAL DEL DECLARADO.
				datos += rellenar(40, " ", "ISMAEL CAMPS TORTAJADA", "D");
				//pos 76 - 1 CLAVE DE CONDICIÓN DEL DECLARANTE
				datos += "3";
				//pos 77 - 25 TIPO DE TITULARIDAD SOBRE EL BIEN O DERECHO. 
				datos += rellenar(25, " ", "", "I");
				//pos 102 - 1 CLAVE TIPO DE BIEN O DERECHO. 
				datos += "C";
				//pos 103 - 1 SUBCLAVE DE BIEN O DERECHO
				datos += "1";
				//pos 104 - 25 TIPO DE DERECHO REAL SOBRE INMUEBLE. 
				datos += rellenar(25, " ", "", "I");
				//pos 129 - 2 CÓDIGO DEL PAÍS
				datos += "ES";
				//pos 131 - 1 CLAVE DE IDENTIFICACIÓN.
				datos += "V";
				//pos 132 - 12 IDENTIFICACIÓN DE VALORES. 
				datos += rellenar(12, " ", "", "I");
				//pos 144 - 1 CLAVE IDENTIFICACIÓN DE CUENTA
				datos += "I";
				//pos 156 - 34 CODIGO CUENTA
				datos += rellenar(34, " ", "", "I");
				//pos 190 - 41 IDENTIFICACIÓN DE LA ENTIDAD.
				datos += rellenar(41, " ", "", "I");
				//pos 231 - 20 NÚMERO DE IDENTIFICACIÓN FISCAL EN EL PAÍS DE RESIDENCIA FISCAL 
				datos += rellenar(20, " ", "", "I");
				//pos 251 - 164 DOMICILIO DE LA ENTIDAD O UBICACIÓN DEL INMUEBLE. 
					//pos 251 - 52 NOMBRE VÍA PUBLICA Y NÚMERO DE CASA
					datos += rellenar(52, " ", "", "I");
					//pos 302 - 40 COMPLEMENTO.
					datos += rellenar(40, " ", "", "I");
					//pos 343 - 30 POBLACIÓN/CIUDAD. 
					datos += rellenar(30, " ", "", "I");
					//pos 373 - 30 PROVINCIA/REGIÓN/ESTADO.
					datos += rellenar(30, " ", "", "I");
					//pos 403 - 10 CÓDIGO POSTAL (ZIP CODE). 
					datos += rellenar(10, " ", "", "I");
					//pos 413 - 2 CÓDIGO PAÍS. 
					datos += rellenar(2, " ", "", "I");

				//pos 415 - 8 FECHA DE INCORPORACIÓN.
				datos += rellenar(8, "0", "20201212", "I");
				//pos 423 - 1 ORIGEN DEL BIEN O DERECHO.
				datos += "A";
				//pos 424 - 8 FECHA DE EXTINCIÓN.
				datos += rellenar(8, "0", "20201212", "I");
				//pos 432 - 15 VALORACIÓN 1: SALDO O VALOR A 31 DE DICIEMBRE; SALDO O VALOR EN LA FECHA DE EXTINCIÓN; VALORDE ADQUISICIÓN.
					//432 - 1 SIGNO
					datos += " ";
					//pos 433 - 15 Parte entera del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(15, "0", "1050", "I");
					//pos 445 - 2 Parte decimal del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(2, "0", "50", "I");
				//pos 447 - 15 VALORACIÓN 2: IMPORTE O VALOR DE LA TRANSMISIÓN; SALDO MEDIO ÚLTIMO TRIMESTRE.
					//447 - 1 SIGNO
					datos += " ";
					//pos 448 - 15 Parte entera del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(15, "0", "1050", "I");
					//pos 460 - 2 Parte decimal del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(2, "0", "50", "I");
				//pos 462 - 1 CLAVE DE REPRESENTACIÓN DE VALORES. 
				datos += "V";
				//pos 463 - 12 NÚMERO DE VALORES.
					//pos 463 - 10 Parte entera del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(10, "0", "1050", "I");
					//pos 473 - 2 Parte decimal del importe, si no tiene contenido se consignará a ceros.
					datos += rellenar(2, "0", "50", "I");
				//pos 475 - 1 CLAVE TIPO DE BIEN INMUEBLE . 
				datos += "U";
				//pos 476 - 12 PORCENTAJE DE PARTICIPACIÓN.
					//pos 476 - 3 Parte entera del porcentaje; si no tiene contenido, se consignará a ceros. 
					datos += rellenar(3, "0", "90", "I");
					//pos 479 - 2 Parte decimal del porcentaje; Figurará la parte decimal del porcentaje; si no tiene contenido se consignará a ceros. 
					datos += rellenar(2, "0", "50", "I");
				//pos 481 - 20 BLANCOS 
				datos += rellenar(20, " ", "", "I");

				break;
			case 210:

				//pos 1 - 9 Etiqueta modelo
				datos += "<T21001> ";
				//pos 10 - 1 Tipo de declaración
				datos += "M";
				//pos 11 - 9 Persona que realiza la autoliquidación. NIF
				datos += rellenar(20, " ", "", "I");
				//20	125	An	Persona que realiza la autoliquidación. Apellidos y nombre, razón social o denominación
				datos += rellenar(125, " ", "", "I");
				//145	1	An	Persona que realiza la autoliquidación. En su condición de. Contribuyente
				datos += rellenar(1, " ", "", "I");
				//146	1	An	Persona que realiza la autoliquidación. En su condición de. Representante
				datos += rellenar(1, " ", "", "I");
				//147	1	An	Persona que realiza la autoliquidación. En su condición de. Pagador
				datos += rellenar(1, " ", "", "I");
				 //148	1	An	Persona que realiza la autoliquidación. En su condición de. Depositario
				 datos += rellenar(1, " ", "", "I");
				 //149	1	An	Persona que realiza la autoliquidación. En su condición de. Gestor
				 datos += rellenar(1, " ", "", "I");
				 //150	1	An	Persona que realiza la autoliquidación. En su condición de. Retenedor
				 datos += rellenar(1, " ", "", "I");
				 //151	1	An	Devengo. Agrupación
				 datos += rellenar(1, " ", "", "I");
				 //152	2	An	Devengo. Período
				 datos += rellenar(2, " ", "", "I");
				 //154	4	Num	Devengo. Año.
				 datos += rellenar(4, "0", "0", "I");
				 //158	8	Num	Devengo. Fecha de devengo.
				 datos += rellenar(8, "0", "0", "I");
				 //166	2	An	Renta obtenida. Tipo renta
				 datos += rellenar(2, " ", "", "I");
				 //168	3	An	Renta obtenida. Clave de divisa
				 datos += rellenar(3, " ", "", "I");
				 //171	9	An	Contribuyente. NIF
				 datos += rellenar(9, " ", "", "I");
				 //180	1	An	Contribuyente. F/J
				 datos += rellenar(1, " ", "", "I");
				 //181	125	An	Contribuyente. Apellidos y nombre, razón social o denominación
				 datos += rellenar(125, " ", "", "I");
				 //306	15	An	Contribuyente. N.I.F. en el país de residencia
				 datos += rellenar(15, " ", "", "I");
				 //321	8	Num	Contribuyente. Fecha de nacimiento.
				 datos += rellenar(8, "0", "0", "I");
				 //329	30	An	Contribuyente. Lugar de nacimiento. Ciudad
				 datos += rellenar(30, " ", "", "I");
				 //359	2	An	Contribuyente. Lugar de nacimiento. Código País
				 datos += rellenar(2, " ", "", "I");
				 //361	2	An	Contribuyente. Residencia fiscal. Código País
				 datos += rellenar(2, " ", "", "I");
				 //363	50	An	Contribuyente. Direción en el país de residencia. Domicilio
				 datos += rellenar(50, " ", "", "I");
				 //413	40	An	Contribuyente. Direción en el país de residencia. Datos complementarios del domicilio
				 datos += rellenar(40, " ", "", "I");
				 //453	30	An	Contribuyente. Direción en el país de residencia. Población/Ciudad
				 datos += rellenar(30, " ", "", "I");
				 //483	100	An	Contribuyente. Direción en el país de residencia. Correo electrónico
				 datos += rellenar(100, " ", "", "I");
				 //583	10	An	Contribuyente. Direción en el país de residencia. Código Postal (ZIP)
				 datos += rellenar(10, " ", "", "I");
				 //593	30	An	Contribuyente. Direción en el país de residencia. Provincia/Región/Estado
				 datos += rellenar(30, " ", "", "I");
				 //623	2	An	Contribuyente. Direción en el país de residencia. Código País
				 datos += rellenar(2, " ", "", "I");
				 //625	15	An	Contribuyente. Direción en el país de residencia. Teléfono fijo
				 datos += rellenar(15, " ", "", "I");
				 //640	15	An	Contribuyente. Direción en el país de residencia. Teléfono móvil
				 datos += rellenar(15, " ", "", "I");
				 //655	15	An	Contribuyente. Direción en el país de residencia. N.º de FAX
				 datos += rellenar(15, " ", "", "I");
				 //670	9	An	Representante del contribuyente. N.I.F.
				 datos += rellenar(9, " ", "", "I");
				 //679	1	An	Representante del contribuyente. F/J
				 datos += rellenar(1, " ", "", "I");
				 //680	125	An	Representante del contribuyente. Apellidos y nombre, razón social o denominación
				 datos += rellenar(125, " ", "", "I");
				 //805	1	An	Representante del contribuyente. Representante: Legal o Voluntario
				 datos += rellenar(1, " ", "", "I");
				 //806	5	An	Representante del contribuyente. Domicilio. Tipo de Vía
				 datos += rellenar(5, " ", "", "I");
				 //811	50	An	Representante del contribuyente. Domicilio. Nombre de la Vía Pública
				 datos += rellenar(50, " ", "", "I");
				 //861	3	An	Representante del contribuyente. Domicilio. Tipo de numeración
				 datos += rellenar(3, " ", "", "I");
				 //864	5	Num	Representante del contribuyente. Domicilio. Número de casa
				 datos += rellenar(5, "0", "0", "I");
				 //869	3	An	Representante del contribuyente. Domicilio. Calificador del número
				 datos += rellenar(3, " ", "", "I");
				 //872	3	An	Representante del contribuyente. Domicilio. Bloque
				 datos += rellenar(3, " ", "", "I");
				 //875	3	An	Representante del contribuyente. Domicilio. Portal
				 datos += rellenar(3, " ", "", "I");
				 //878	3	An	Representante del contribuyente. Domicilio. Escalera
				 datos += rellenar(3, " ", "", "I");
				 //881	3	An	Representante del contribuyente. Domicilio. Planta
				 datos += rellenar(3, " ", "", "I");
				 //884	3	An	Representante del contribuyente. Domicilio. Puerta
				 datos += rellenar(3, " ", "", "I");
				 //887	40	An	Representante del contribuyente. Domicilio. Datos complementarios del domicilio
				 datos += rellenar(40, " ", "", "I");
				 //927	30	An	Representante del contribuyente. Domicilio. Localidad / Población (si es distinta del municipio)
				 datos += rellenar(30, " ", "", "I");
				 //957	5	Num	Representante del contribuyente. Domicilio. Código Postal
				 datos += rellenar(5, "0", "0", "I");
				 //962	30	An	Reservado para la Administración
				 datos += rellenar(30, " ", "", "I");
				 //992	5	Num	Representante del contribuyente. Domicilio. Código INE del Municipio
				 datos += rellenar(5, "0", "0", "I");
				 //997	2	Num	Representante del contribuyente. Domicilio. Código Provincia
				 datos += rellenar(2, "0", "0", "I");
				 //999	9	An	Representante del contribuyente. Domicilio. Teléfono. fi jo
				 datos += rellenar(9, " ", "", "I");
				 //1008	9	An	Representante del contribuyente. Domicilio. Teléfono. móvil
				 datos += rellenar(9, " ", "", "I");
				 //1017	9	An	Representante del contribuyente. Domicilio. N.º de FAX
				 datos += rellenar(9, " ", "", "I");
				 //1026	9	An	Pagador/Retenedor/Emisor/Adquiriente del inmueble. N.I.F.
				 datos += rellenar(9, " ", "", "I");
				 //1035	1	An	Pagador/Retenedor/Emisor/Adquiriente del inmueble. F/J.
				 datos += rellenar(1, " ", "", "I");
				 //1036	125	An	Pagador/Retenedor/Emisor/Adquiriente del inmueble. Apellidos y nombre, razón social o denominación
				 datos += rellenar(125, " ", "", "I");
				 //1161	5	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Tipo de Vía
				 datos += rellenar(5, " ", "", "I");
				 //1166	50	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Nombre de la Vía Pública
				 datos += rellenar(50, " ", "", "I");
				 //1216	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Tipo de numeración
				 datos += rellenar(3, " ", "", "I");
				 //1219	5	Num	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Número de casa
				 datos += rellenar(5, "0", "0", "I");
				 //1224	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Calificador del número
				 datos += rellenar(3, " ", "", "I");
				 //1227	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Bloque
				 datos += rellenar(3, " ", "", "I");
				 //1230	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Portal
				 datos += rellenar(3, " ", "", "I");
				 //1233	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Escalera
				 datos += rellenar(3, " ", "", "I");
				 //1236	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Planta
				 datos += rellenar(3, " ", "", "I");
				 //1239	3	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Puerta
				 datos += rellenar(3, " ", "", "I");
				 //1242	40	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Datos complementarios del domicilio
				 datos += rellenar(40, " ", "", "I");
				 //1282	30	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Localidad / Población (si es distinta del municipio)
				 datos += rellenar(30, " ", "", "I");
				 //1312	5	Num	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Código Postal
				 datos += rellenar(5, "0", "0", "I");
				 //1317	30	An	Reservado para la Administración
				 datos += rellenar(30, " ", "", "I");
				 //1347	5	Num	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Código INE del Municipio
				 datos += rellenar(5, "0", "0", "I");
				 //1352	2	Num	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Código Provincia
				 datos += rellenar(2, "0", "0", "I");
				 //1354	20	An	Situación del inmueble (sólo rentas de los tipos 01, 02 y 28). Referencia catastral
				 datos += rellenar(20, " ", "", "I");
				 //1374	17	Num	Determinación de la base imponible. 210 I. Base imponible  [4]
				 datos += rellenar(17, "0", "0", "I");
				 //1391	17	Num	Determinación de la base imponible. 210 R. Rendimientos integros  [5]
				 datos += rellenar(17, "0", "0", "I");
				 //1408	17	N	Determinación de la base imponible. 210 R. Exención aplicada dividendos (limite anual 1500 euros)  [6]
				 datos += rellenar(17, "0", "0", "I");
				 //1425	17	N	Determinación de la base imponible. 210 R. Gastos deducibles [7]
				 datos += rellenar(17, "0", "0", "I");
				 //1442	17	N	Determinación de la base imponible. 210 R. Base imponible ([5]-[6]-[7]) [8]
				 datos += rellenar(17, "0", "0", "I");
				 //1459	1	An	Determinación de la base imponible. 210 H. C/O
				 datos += rellenar(1, " ", "", "I");
				 //1460	5	Num	Determinación de la base imponible. 210 H. Cuota participación. Contribuyente
				 datos += rellenar(5, "0", "0", "I");
				 //1465	5	Num	Determinación de la base imponible. 210 H. Cuota participación. Conyuge
				 datos += rellenar(5, "0", "0", "I");
				 //1470	9	An	Determinación de la base imponible. 210 H. Cónyuge. N.I.F.
				 datos += rellenar(9, "0", "0", "I");
				 //1479	40	An	Determinación de la base imponible. 210 H. Cónyuge. Apellidos y nombre
				 datos += rellenar(40, " ", "", "I");
				 //1519	17	N	Determinación de la base imponible. 210 H. Adquisición. Valor de transmisión [9]
				 datos += rellenar(17, "0", "0", "I");
				 //1536	17	N	Determinación de la base imponible. 210 H. Adquisición. Valor de adquisición (actualizado)  [10]
				 datos += rellenar(17, "0", "0", "I");
				 //1553	17	N	Determinación de la base imponible. 210 H. Adquisición. Diferencia [11]
				 datos += rellenar(17, "0", "0", "I");
				 //1570	17	N	Determinación de la base imponible. 210 H. Adquisición. Ganancia [12]
				 datos += rellenar(17, "0", "0", "I");
				 //1587	17	N	Determinación de la base imponible. 210 H. Mejora o 2ª adquisición. Valor de transmisión [13]
				 datos += rellenar(17, "0", "0", "I");
				 //1604	17	N	Determinación de la base imponible. 210 H. Mejora o 2ª adquisición. Valor de adquisición (actualizado)  [14]
				 datos += rellenar(17, "0", "0", "I");
				 //1621	17	N	Determinación de la base imponible. 210 H. Mejora o 2ª adquisición. Diferencia [15]
				 datos += rellenar(17, "0", "0", "I");
				 //1638	17	N	Determinación de la base imponible. 210 H. Mejora o 2ª adquisición. Ganancia [16]
				 datos += rellenar(17, "0", "0", "I");
				 //1655	17	N	Determinación de la base imponible. 210 H. Base imponible ([12]+[16]) [17]
				 datos += rellenar(17, "0", "0", "I");
				 //1672	8	Num	Determinación de la base imponible. 210 H. Fecha de adquisición
				 datos += rellenar(8, "0", "0", "I");
				 //1680	8	Num	Determinación de la base imponible. 210 H. Fecha de mejora o 2ª adquisición
				 datos += rellenar(8, "0", "0", "I");
				 //1688	13	An	Determinación de la base imponible. 210 H. Número de justificante del modelo 211
				 datos += rellenar(13, " ", "", "I");
				 //1701	17	N	Determinación de la base imponible. 210 G. Base imponible [18]
				 datos += rellenar(17, "0", "0", "I");
				 //1718	1	An	Liquidación. Exenciones. Ley IRNR, excepto dividendos (límite anual 1.500 euros) [19]
				 datos += rellenar(1, " ", "", "I");
				 //1719	1	An	Liquidación. Convenio [20]
				 datos += rellenar(1, " ", "", "I");
				 //1720	5	Num	Liquidación. Tipo de gravamen Ley IRNR (%) [21]
				 datos += rellenar(5, "0", "0", "I");
				 //1725	17	N	Liquidación. Cuota íntegra [22]
				 datos += rellenar(17, "0", "0", "I");
				 //1742	17	Num	Liquidación. Deducción por donativos [23]
				 datos += rellenar(17, "0", "0", "I");
				 //1759	17	N	Liquidación. Cuota Ley IRNR (22) - (23) [24]
				 datos += rellenar(17, "0", "0", "I");
				 //1776	5	Num	Liquidación. Porcentaje Convenio (%) [25]
				 datos += rellenar(5, "0", "0", "I");
				 //1781	17	Num	Liquidación. Límite Convenio [26]
				 datos += rellenar(17, "0", "0", "I");
				 //1798	17	Num	Liquidación. Reducción por Convenio (24) - (26) [27]
				 datos += rellenar(17, "0", "0", "I");
				 //1815	17	Num	Liquidación. Cuota íntegra reducida (24) - (27) [28]
				 datos += rellenar(17, "0", "0", "I");
				 //1832	17	Num	Liquidación. Retenciones/Ingresos a cuenta [29]
				 datos += rellenar(17, "0", "0", "I");
				 //1849	17	N	Liquidación. Ingreso/Devolución anterior. Exclusivamente en caso de autoliquidacion complementaria [30]
				 datos += rellenar(17, "0", "0", "I");
				 //1866	17	N	Liquidación. Resultado de la autoliquidación (28) - (29) ± (30) [31]
				 datos += rellenar(17, "0", "0", "I");
				 //1883	1	An	Autoliquidación complementaria. Indicador
				 datos += rellenar(1, "0", "0", "I");
				 //1884	13	An	Autoliquidación complementaria. Nº de justificante de la autoliquidación anterior
				 datos += rellenar(13, " ", "", "I");
				 //1897	100	An	Persona de contacto
				 datos += rellenar(100, " ", "", "I");
				 //1997	15	Num	Teléfono 1
				 datos += rellenar(15, "0", "0", "I");
				 //2012	15	Num	Teléfono 2
				 datos += rellenar(15, "0", "0", "I");
				 //2027	100	An	Correo electronico
				 datos += rellenar(100, " ", "", "I");
				 //2127	13	An	Reservado para la Administración. Sello electrónico de la AEAT
				 datos += rellenar(13, " ", "", "I");
				 //2140	552	An	Reservado para la Administración
				 datos += rellenar(13, " ", "", "I");
				 //2692	9	An	Indicador de fin de registro
				 datos += "</T21001>";

				//pos 1 - 9 Etiqueta modelo
				datos += "<T21001> ";
				//pos 10 - 1 Forma de Pago
				datos += "";
				//2140	552	An	Reservado para la Administración
				datos += rellenar(13, " ", "", "I");
				break;
		}




		var fileName = "datos." + modelo;

		saveTextAsFile(datos, fileName);
	}
}

function rellenar(longitud, caracter, cadena, lado) {
	let cadenaFinal = cadena;
	while (cadenaFinal.length < longitud) {
		if (lado == "I") {
			cadenaFinal = caracter + cadenaFinal;
		} else {
			cadenaFinal = cadenaFinal + caracter;
		}
	}
	return cadenaFinal;
}

function saveTextAsFile(data, filename) {

	if (!data) {
		console.error('Console.save: No data')
		return;
	}

	if (!filename) filename = 'console.json'

	var blob = new Blob([data], { type: 'text/plain' }),
		e = document.createEvent('MouseEvents'),
		a = document.createElement('a')
	// FOR IE:


	var e = document.createEvent('MouseEvents'),
		a = document.createElement('a');

	a.download = filename;
	a.href = window.URL.createObjectURL(blob);
	a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
	e.initEvent('click', true, false);
	a.dispatchEvent(e);

}

