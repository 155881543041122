import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'kt-beckham-law',
  templateUrl: './beckham-law.component.html',
  styleUrls: ['./beckham-law.component.scss']
})
export class BeckhamLawComponent implements OnInit {

  loaded: boolean;
  modeloBeckham: FormGroup;
  form_errors: boolean;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService
  ) {
    this.loaded = false;
    this.form_errors = false;
  }

  ngOnInit() {
    this.inicializarFormulario();
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modeloBeckham.get('idInput').setValue(res['respuesta']['person_dni']);
        this.modeloBeckham.get('nombre').setValue(res['respuesta']['person_full_name'].split(' ')[0]);
        this.modeloBeckham.get('apellidos').setValue(res['respuesta']['person_full_name'].split(' ')[1]);
        this.cdRef.detectChanges();
      });
    }
  }

  guardarFormulario() {
    if (this.modeloBeckham.valid) {
      this.api.willianApi({ accion: "GenerarModeloBeckhamLaw", argumentos: this.modeloBeckham.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      });
      this.form_errors = false;
    } else {
      this.form_errors = true;
      return;
    }
  }

  inicializarFormulario() {
    this.modeloBeckham = this.fb.group({
      idInput: "",
      nombre: "",
      apellidos: "",
      property_deed: "",
      laboral_contract: "",
      comentarios: "",
      id_usuario: ""
    });
    if (this.loginService.devolver) {
      this.modeloBeckham.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }

  CatchPropertyDeed(event) {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    this.api.subirArchivo(formData).subscribe(res => {
      this.modeloBeckham.get('property_deed').setValue(res.toString());
    });
  }

  CatchLaboralContract(event) {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    this.api.subirArchivo(formData).subscribe(res => {
      this.modeloBeckham.get('laboral_contract').setValue(res.toString());
    });
  }

}
