import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'kt-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {
  userForm: FormGroup

  constructor(
    private fb: FormBuilder, private api: ApiService, private router: Router, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.createForm();
    this.obtenerDatos();
  }

  createForm() {
    this.userForm = this.fb.group({
      id_usurio: [localStorage.getItem('id_usuario'), Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      nie_passport: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      location: ['', Validators.required],
      country: [''],
      civil_status: [''],
    });
  }

  obtenerDatos() {
    this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
      this.userForm = this.fb.group({
        id_usurio: [localStorage.getItem('id_usuario'), Validators.required],
        name: [res['respuesta']['person_full_name'].split(' ')[0], Validators.required],
        surname: [res['respuesta']['person_full_name'].split(' ')[1], Validators.required],
        email: [res['respuesta']['usuario_email'], [Validators.required, Validators.email]],
        phone: [res['respuesta']['person_tel1'], Validators.required],
        nie_passport: [res['respuesta']['person_dni'], Validators.required],
        address: [res['respuesta']['direccion_calle'], Validators.required],
        zip: [res['respuesta']['direcion_codigo_postal'], Validators.required],
        location: [res['respuesta']['direccion_pueblo'], Validators.required],
        country: [res['respuesta']['direccion_pais']],
        civil_status: [res['respuesta']['person_estado_civil']],
      });
      this.cdRef.detectChanges();
    });
  }

  updateUser() {
    if (this.userForm.valid) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'ActualizarDatosUsuarioPanel', argumentos: this.userForm.value }).subscribe(res => {

        if (res['respuesta']) {
          this.router.navigate(['/user-panel']);
        } else {
          console.log(res['respuesta']);
        }
      });
    } else { }
  }
}
