import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { FooterService } from '../../services/footer/footer.service';
import { HeaderService } from '../../services/header/header.service';

@Component({
  selector: 'kt-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.scss']
})
export class FacturaComponent implements OnInit, OnDestroy {
  serie_factura: string;
  factura: number;
  person_full_name: string;
  person_dni: string;
  usuario_email: string;
  person_tel1: string;
  direccion_calle: string;
  id_modelo: number;
  model_code: string;
  model_price: number;
  fecha_creacion: string;
  total: number;
  iva:number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private header: HeaderService,
    private footer: FooterService,
    private api: ApiService,
    private cdRef: ChangeDetectorRef) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.buscarFactura(params.id);
    });
  }

  buscarFactura(id: number) {
    this.api.willianApi(
      {
        modelo: "relaciones_modelos",
        accion: "ObtenerFactura",
        argumentos: {
          id_relacion: id
        }
      }).subscribe(res => {
        this.factura = res['respuesta']['id_factura'];
        this.person_full_name = res['respuesta']['person_full_name'];
        this.person_dni = res['respuesta']['person_dni'];
        this.usuario_email = res['respuesta']['usuario_email'];
        this.person_tel1 = res['respuesta']['person_tel1'];
        this.direccion_calle = res['respuesta']['direccion_calle'];
        this.id_modelo = res['respuesta']['id_modelo'];
        this.model_code = res['respuesta']['model_code'];
        this.model_price = res['respuesta']['importe'];
        this.fecha_creacion = res['respuesta']['fecha_creacion'];
        this.total = parseInt(res['respuesta']['importe']) + parseInt(res['respuesta']['importe']) * 0.21;
        this.cdRef.detectChanges();
      });
  }
  
  ngOnDestroy() {
    this.header.show();
    this.footer.show();
  }
}
