
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { LoginService } from '../../services/login/login.service';

import * as $ from 'jquery';
@Component({
  selector: 'kt-panel-usuario',
  templateUrl: './panel-usuario.component.html',
  styleUrls: ['./panel-usuario.component.scss']
})
export class PanelUsuarioComponent implements OnInit {

  usr_logged: boolean;
  input_buscar: string;
  loaded: boolean;
  nombre: string;
  apellido: string;
  email: string;
  telefono: string;
  nie: string;
  direccion: string;
  cod_postal: number;
  pueblo: string;
  pais: string;
  estado_civil: string;
  estado_civil_array: string[];
  relacion_modelos: any[];
  facturaPdf: object;
  constructor(
    private router: Router,
    private api: ApiService,
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService) {
    this.estado_civil_array = ['Single', 'Married', 'Divorced', 'Widowed'];
    this.facturaPdf = {};
  }

  ngOnInit() {
    if (this.loginService.devolver()) {
      this.loaded = true;
      this.usr_logged = true;
      this.datosUsuario();
      this.obtenerModelos();
    } else {
      this.router.navigate(['/login']);
      this.usr_logged = false;
    }
  }


  datosUsuario() {
    this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
      this.nombre = res['respuesta']['person_full_name'].split(' ')[0];
      this.apellido = res['respuesta']['person_full_name'].split(' ')[1];
      this.email = res['respuesta']['usuario_email'];
      this.telefono = res['respuesta']['person_tel1'];
      this.nie = res['respuesta']['person_dni'];
      this.direccion = res['respuesta']['direccion_calle'];
      this.pueblo = res['respuesta']['direccion_pueblo'];
      this.cod_postal = res['respuesta']['direcion_codigo_postal'];
      this.pais = res['respuesta']['pais_nombre'];
      this.estado_civil = this.estado_civil_array[res['respuesta']['person_estado_civil']];
      this.cdRef.detectChanges();
    });
  }

  obtenerModelos() {
    this.api.willianApi({ modelo: "usuarios", accion: "obtenerModelos", argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
      this.relacion_modelos = res['respuesta'];
      this.cdRef.detectChanges();
      //console.log( res['respuesta']);
    });
  }

  boton1(): void {
    //$('#btn-billings').removeClass('active-tab');
    $('#btn-models').removeClass('active-tab');
    $('#btn-user').addClass('active-tab');
    $('#billings').fadeOut(250);
    $('#models').fadeOut(250);
    setTimeout(function () {
      $('#user').fadeIn(250);
    }, 350);
  }

  boton2(): void {
    $('#btn-user').removeClass('active-tab');
    $('#btn-models').removeClass('active-tab');
    //$('#btn-billings').addClass('active-tab');
    $('#user').fadeOut(250);
    $('#models').fadeOut(250);
    setTimeout(function () {
      $('#billings').fadeIn(250);
    }, 350);
  }

  boton3(): void {
    $('#btn-user').removeClass('active-tab');
    //$('#btn-billings').removeClass('active-tab');
    $('#btn-models').addClass('active-tab');
    $('#user').fadeOut(250);
    $('#billings').fadeOut(250);
    setTimeout(function () {
      $('#models').fadeIn(250);
    }, 350);
  }

  updateUser() {
    this.router.navigate(['/update-user']);
  }

  changePassword() {
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      maxWidth: '400px'
    }
    const dialogRef = this.matDialog.open(UpdatePasswordComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res !== false && res !== '' && res !== null && res !== undefined) {
        this.api.willianApi({ accion: 'CambiarPassword', argumentos: { contrasenna: res, email: this.email } }).subscribe(res => {
          console.log(res['respuesta'])
        });
      }
    });
  }

  buscarModelo() {
    this.api.willianApi({
      modelo: 'relaciones_modelos',
      accion: 'buscadorModelos',
      argumentos: {
        id_usuario: localStorage.getItem('id_usuario'),
        criterio: this.input_buscar
      }
    }).subscribe(
      res => {
        if (res['respuesta'].length > 0) {
          this.relacion_modelos = res['respuesta'];
          this.cdRef.detectChanges();
        } else {
          this.obtenerModelos();
        }
      });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
    this.loginService.setFalse();
    this.cdRef.detectChanges();
  }
}
