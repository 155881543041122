import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-activar-usuario',
  templateUrl: './activar-usuario.component.html',
  styleUrls: ['./activar-usuario.component.scss']
})
export class ActivarUsuarioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
