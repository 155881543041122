
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { LoginService } from '../../../services/login/login.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'kt-special-tax',
  templateUrl: './special-tax.component.html',
  styleUrls: ['./special-tax.component.scss']
})
export class SpecialTaxComponent implements OnInit {

  SpecialTaxForm: FormGroup;
  loaded: boolean;
  form_errors: boolean;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService,
  ) {
    this.loaded = false;
    this.form_errors = false;
  }

  ngOnInit() {
    this.inicializarFormulario();
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        this.SpecialTaxForm.get('name').setValue(res['respuesta']['person_full_name'].split(' ')[0]);
        this.SpecialTaxForm.get('surname').setValue(res['respuesta']['person_full_name'].split(' ')[1]);
        this.SpecialTaxForm.get('email').setValue(res['respuesta']['usuario_email']);
        this.SpecialTaxForm.get('phone').setValue(res['respuesta']['person_tel1']);
        this.cdRef.detectChanges();
      });
    }
  }


  inicializarFormulario() {
    this.SpecialTaxForm = this.fb.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      comentarios: ['', [Validators.required]],
      id_usuario: [""]
    });
    if (this.loginService.devolver()) {
      this.SpecialTaxForm.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }

  subirFormulario() {

    if (this.SpecialTaxForm.valid) {
      this.api.willianApi({ accion: 'GuardarFormularioSpecialTax', argumentos: this.SpecialTaxForm.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      });
    }
    else {
      this.form_errors = true;
      return;
    }
  }

}
