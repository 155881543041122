
import { Pais } from "./pais.models";

export class Address {
    id_direccion: number;
    direccion_provincia: string;
    direccion_pueblo: string;
    direcion_codigo_postal: number;
    direccion_calle: string;
    direccion_extra: string;
    pais: Pais;

    clear() {
        this.id_direccion = undefined;
        this.direccion_provincia = '';
        this.direccion_pueblo = '';
        this.direcion_codigo_postal = undefined;
        this.direccion_calle = '';
        this.direccion_extra = '';
        this.pais = new Pais();
    }
}
