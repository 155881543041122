import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'kt-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  paises: object[];
  registerForm: FormGroup;
  form_erros: boolean;
  constructor(public api: ApiService, private router: Router, private formBuilder: FormBuilder) {
    this.form_erros = false;

    this.paises = [];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      pass: ['', [Validators.required]],
      confPass: ['', [Validators.required]],
      civil_status: [0],
      situacion: [0],
      address: ['', [Validators.required]],
      nie_passport: ['', [Validators.required]],
      location: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      i_have_signature: [''],
    });
    this.paises = [];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });

  }

  ngOnDestroy() {

  }
  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  
  goToPage(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  creaUsuario() {
    if (this.registerForm.value.pass === this.registerForm.value.confPass && this.registerForm.valid) {
      this.api.willianApi({ accion: 'RegistrarUsuario', argumentos: this.registerForm.value }).subscribe(res => {
        this.router.navigate(['/activate-user']);
      }, error => {
        console.log(error);
      });
    } else {
      this.form_erros = true;
      return;
    }
  }


}
