import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
declare var $: any;

@Component({
  selector: 'kt-formulario100',
  templateUrl: './formulario100.component.html',
  styleUrls: ['./formulario100.component.scss']
})

export class Formulario100Component implements OnInit {
  modelo100Form: FormGroup;
  arr1: boolean[] = [];
  situacionAsistente = 0;
  loaded: boolean;
  form_errors: boolean;
  listaArchivosArrayString: string[];
  form_array_errors: boolean;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService
  ) {
    this.loaded = false;
    this.listaArchivosArrayString = [];
    this.form_errors = false;
    this.form_array_errors = false;
  }

  ngOnInit() {
    this.inicializarFormulario();
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modelo100Form.get('DNI').setValue(res['respuesta']['person_dni']);
        this.modelo100Form.get('nombreORS').setValue(res['respuesta']['person_full_name']);
        this.modelo100Form.get('estadoCivil').setValue(res['respuesta']['person_estado_civil']);
        this.cdRef.detectChanges();
      });
    }
  }

  inicializarFormulario() {
    this.modelo100Form = this.fb.group({

      /* parte 1 */
      DNI: ["", [Validators.required]],
      nombreORS: ["", [Validators.required]],
      estadoCivil: ["", [Validators.required]],
      fechaNacimiento: ["", [Validators.required]],
      genero: [""],
      discapacidad: ["", [Validators.required]],
      comunidadAutonoma: ["", [Validators.required]],

      /* si tiene esposa */
      idConyuge: "",
      nombreConyuge: "",
      fechaNacimientoConyuge: "",
      generoConyuge: "",
      discapacidadConyuge: "",
      comunidadAutonoma2: "",
      ckbox_spouse1: "",
      ckbox_spouse2: "",

      /* parte 2 */
      hijos: new FormArray([]),
      hijos2: new FormArray([]),
      ascendientes: new FormArray([]),

      /* parte 3 */
      typeofdeclarant: "",

      /* parte 4 */
      tipoPago: "",
      cuentaBancaria: ["", [Validators.required]],
      comentarios: "",
      id_usuario: "",
      listaArchivos: '',

      /* campos desactivados
       fechaFallecimiento: "", 
       */
    });

    this.arr1 = [true, false, false, false];

    if (this.loginService.devolver()) {
      this.modelo100Form.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }

  guardarFormPrincipal(): void {
    if (this.modelo100Form.valid) {
      this.subirArchivos();
      this.api.willianApi({ accion: 'GuardarModelo100', argumentos: this.modelo100Form.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      }, error => {
        console.log(error);
      });
    } else {
      var page = this.buscarPaginaPrimerError();
      if (page !== -1) {
        this.situacionAsistente = page;
        for (let index = 0; index < this.arr1.length; index++) {
          if (page === index) {
            this.arr1[index] = true;
          } else {
            this.arr1[index] = false;
          }
        }
      }
      this.form_errors = true;
      return;
    }

  }

  buscarPaginaPrimerError() {
    var page = -1;
    var cuenta = 0;
    if (//parte 1
      this.modelo100Form.controls['DNI'].errors ||
      this.modelo100Form.controls['nombreORS'].errors ||
      this.modelo100Form.controls['estadoCivil'].errors ||
      this.modelo100Form.controls['fechaNacimiento'].errors ||
      this.modelo100Form.controls['discapacidad'].errors ||
      this.modelo100Form.controls['comunidadAutonoma'].errors
    ) {
      page = 0; cuenta++;
    } else if (//parte 3
      (this.modelo100Form.controls['cuentaBancaria'].errors) && cuenta === 0) {
      page = 3;
    }
    return page;
  }
  
  subirArchivos() {
    for (let index = 0; index < this.files.length; index++) {
      const formData = new FormData();
      formData.append("file", this.files[index]);
      this.api.subirArchivo(formData).subscribe(res => {
        this.listaArchivosArrayString.push(res.toString());
      });
    }
    this.modelo100Form.get('listaArchivos').setValue(this.listaArchivosArrayString);
  }

  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }


  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  anteriorPag(): void {
    if (this.situacionAsistente > 0) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente--;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }
  }

  siguientePag(): void {
    if (this.situacionAsistente < 8) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente++;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }

  }

  //form array de hijos
  anadirHijos() {
    for (let control of this.modelo100Form.get('hijos')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      nombreHijo: "",
      idHijo: "",
      fechaNacimientoHijo: "",
      vinculacionHijo: "",
      discapacidadHijo: "",
      situacionesHijo: "",
      generoHijo: "",
      comunidadAutonomaHijo: "",
      fechaAdopcion: "",
      fechaFallecimientohijo: "",
      unidadFamiliar: "",
      nombreProgenitor: "",
      idProgenitor: "",
      generoProgenitor: "",
      saved: 0
    });

    const hijos = this.modelo100Form.get('hijos') as FormArray;
    hijos.push(group);
    this.modelo100Form.controls['hijos'] = hijos;
    this.cdRef.detectChanges();
  }

  guardarFormHijos(i: number): void {
    const hijos = (<FormArray>this.modelo100Form.get('hijos')).at(i);
    if (hijos.valid) {
      this.form_array_errors = false;
      hijos.patchValue({ saved: 1 });
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormHijos(i: number): void {
    const hijos = (<FormArray>this.modelo100Form.get('hijos')).at(i);
    hijos.patchValue({ saved: 0 });
  }

  eliminarFormHijos(i: number): void {
    (<FormArray>this.modelo100Form.get('hijos')).removeAt(i);
  }

  //forms array de hijos2
  anadirHijos2() {
    for (let control of this.modelo100Form.get('hijos2')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      nombreHijo2: "",
      idHijo2: "",
      fechaNacimientoHijo2: "",
      fechaAdopcion3: "",
      vinculacionHijo2: "",
      discapacidadHijo2: "",
      situacionesHijo2: "",
      generoHijo2: "",
      fechaAdopcion2: "",
      fechaFallecimientohijo30: "",
      nombreProgenitor2: "",
      idProgenitor2: "",
      generoProgenitor2: "",
      saved: 0
    });

    const hijos2 = this.modelo100Form.get('hijos2') as FormArray;
    hijos2.push(group);
    this.modelo100Form.controls['hijos2'] = hijos2;
    this.cdRef.detectChanges();
  }

  guardarFormHijos2(i: number): void {
    const hijos2 = (<FormArray>this.modelo100Form.get('hijos2')).at(i);
    if (hijos2.valid) {
      hijos2.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormHijos2(i: number): void {
    const hijos2 = (<FormArray>this.modelo100Form.get('hijos2')).at(i);
    hijos2.patchValue({ saved: 0 });
  }

  eliminarFormHijos2(i: number): void {
    (<FormArray>this.modelo100Form.get('hijos2')).removeAt(i);
  }

  //forms array de ascendientes
  anadirAscendientes() {
    for (let control of this.modelo100Form.get('ascendientes')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      idAscendiente: [""],
      nombreAscendiente: [""],
      fechaNacimientoAscendiente: [""],
      vinculacionAscendiente: [""],
      discapacidadAscendiente: [""],
      numAscendientes: [""],
      fechaFallecimientoAscendiente: [""],
      saved: 0
    });

    const ascendientes = this.modelo100Form.get('ascendientes') as FormArray;
    ascendientes.push(group);
    this.modelo100Form.controls['ascendientes'] = ascendientes;
    this.cdRef.detectChanges();
  }

  guardarFormAscendientes(i: number): void {
    const ascendientes = (<FormArray>this.modelo100Form.get('ascendientes')).at(i);
    if (ascendientes.valid) {
      ascendientes.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormAscendientes(i: number): void {
    const ascendientes = (<FormArray>this.modelo100Form.get('ascendientes')).at(i);
    ascendientes.patchValue({ saved: 0 });
  }

  eliminarFormAscendientes(i: number): void {
    (<FormArray>this.modelo100Form.get('ascendientes')).removeAt(i);
  }

}
