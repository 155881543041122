import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {

    id_usuario: number;
    username: string;
    password: string;
    usuario_email: string;
    usuario_rol: number;
    usuario_foto: string;
    accessToken: string;
    id_person: number;
    usuario_status: number;

    clear(): void {
        this.id_usuario = undefined;
        this.username = '';
        this.password = '';
        this.usuario_email = '';
        this.usuario_rol = 2;
        this.accessToken = 'access-token-' + Math.random();
        this.usuario_foto = '';
        this.id_person = null;
        this.usuario_status = 1;
    }
}
