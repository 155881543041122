import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  updatePasswordForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<UpdatePasswordComponent>, private fb: FormBuilder) { }

  ngOnInit() {
    this.updatePasswordForm = this.fb.group({
      new_password: ['', [Validators.required]],
      repeat_password: ['', [Validators.required]],
    });
  }
  accept() {
    if (this.updatePasswordForm.valid && (this.updatePasswordForm.get('new_password').value === this.updatePasswordForm.get('repeat_password').value)) {
      this.dialogRef.close(this.updatePasswordForm.get('new_password').value);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
