import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	//URL: string = 'http://192.168.1.72/myspanishtaxes/back/';
	URL: string = 'https://www.myspanishtaxes.com/back/';
	URL_PAGO: string = 'https://www.myspanishtaxes.com/api/payment/';

	constructor(protected http: HttpClient) { }

	willianApi(datos: Object) {
		var httpHeaders = new HttpHeaders();
		if (localStorage.getItem('accessToken') !== '') {
			httpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') });
		}
		return this.http.post(this.URL, datos, { headers: httpHeaders });
	}

	apiPanel(datos: Object) {
		var httpHeaders = new HttpHeaders();
		if (localStorage.getItem('accessToken') !== '') {
			httpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') });
		}
		return this.http.post(this.URL + 'api-panel.php', datos, { headers: httpHeaders });
	}

	ver_cabeceras() {
		const datos = { accion: "ListarUsuarios", modelo: "usuarios" };
		const httpHeaders = new HttpHeaders({ 'Authorization': 'Bearer a4sd654sa56d4sa65d4sa654dsa64dsa64d56sa4d65sa4d65sa4d65sa87dsa87dsa' });
		return this.http.post(this.URL + 'request-headers.php', datos, { headers: httpHeaders });
	}
	subirArchivo(archivo: any) {
		const httpHeaders = new HttpHeaders();
		return this.http.post(this.URL + '/tests/upload.php', archivo, { headers: httpHeaders });
	}
	ver_servidor() {
		const httpHeaders = new HttpHeaders({ 'Authorization': 'Bearer a4sd654sa56d4sa65d4sa654dsa64dsa64d56sa4d65sa4d65sa4d65sa87dsa87dsa' });
		return this.http.post(this.URL + 'server.php', {}, { headers: httpHeaders });
	}

	login(email: string, password: string) {
		return this.http.post(this.URL + `login.php`, { email: email, password: password });
	}

	generateLink(
		orderId: string,
		amount: number,
		email: string,
		supp: string,
		lang: string = 'en'
	): Observable<any> {
		const url = 'https://myspanishtaxes.com/back/pagomyspanish/test/php/HPP/card-payment/pay-by-link/pay.php'

		const headers = new HttpHeaders();

		const body = {
			amount: amount,
			email: email,
			supp: supp,
			lang: lang

			// Si funciona estas líneas comentadas se pueden borrar tranquilamente:
			// formId: "152468748",
			// custId: "2394jadi-Q23R2Q-D21234",
			// comment: "",
			// varRef: "QBCT5478-6h6fq7ed6-f9f3qwf",
			// phone: "671921778",
			// supp: ""
		}

		return this.http.post(encodeURI(url), body, { headers: headers })
	}
}
