// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { User } from '../../../core/auth/_models/user.model';
import { SubheaderService } from '../../../core/_base/layout';
// RXJS
import { Subscription, from } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteUserComponent } from './usuario/delete-user/delete-user.component';

@Component({
	selector: 'kt-usuarios',
	templateUrl: './usuarios.component.html',
	styleUrls: ['./usuarios.component.scss']
})

export class UsuariosComponent implements OnInit, OnDestroy {

	usuarios: User[] = [];
	dataSource;
	lista_usuario: object[];
	displayedColumns: string[] = ['select', 'id', 'username', 'email', 'opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<User>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
		this.lista_usuario = [];
	}

	ngOnInit() {

		this.subheaderService.setTitle('Usuarios');

		this.api.apiPanel({ modelo: 'usuarios', accion: 'listarUsuariosAdmin' }).subscribe(res => {
			if (res['token_status']) {
				for (let o of res['respuesta']) {
					this.usuarios.push(o);
				}
				console.log(this.usuarios);
				this.dataSource = new MatTableDataSource(this.usuarios);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			} else {
				localStorage.clear();
				this.router.navigate(['auth/login']);
			}

		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.usuarios.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.usuarios.length) {
			this.selection.clear();
		} else {
			this.usuarios.forEach(row => this.selection.select(row));
		}
	}

	fetchUsers() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.username}, ${elem.usuario_email}`,
				id: elem.id_usuario.toString(),
				status: elem.username
			});
		});
		console.log(messages);

	}

	editUser(id: number) {

		this.router.navigate(['../usuarios/', id], { relativeTo: this.activatedRoute });
	}

	deleteUser(id: number) {
		let user = this.usuarios.find(i => i.id_usuario === id);
		const dialogConfig: MatDialogConfig = {
			autoFocus: true,
			maxWidth: '400px',
			data: {
				id: user.id_usuario,
				username: user.username,
				email: user.usuario_email
			}
		}
		const dialogRef = this.matDialog.open(DeleteUserComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.api.willianApi({ modelo: 'usuarios', accion: 'Eliminar', argumentos: id }).subscribe(res => {
					if (res['respuesta']) {
						this.dataSource.data = this.dataSource.data.filter(obj => obj.id_usuario !== id);
					}
				});
			}
		});
	}

	recargarUsuarios() {
		this.api.apiPanel({ modelo: 'usuarios', accion: 'listar' }).subscribe(res => {
			this.usuarios = [];
			for (let o of res['data']) {
				this.usuarios.push(o);
			}
			this.dataSource = new MatTableDataSource(this.usuarios);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnDestroy(): void {
		this.header.toggle();
	}
}
