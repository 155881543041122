import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
// Models
import { User } from '../../../../core/auth';
import { Observable } from 'rxjs';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { SubheaderService } from '../../../../core/_base/layout';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
	selector: 'kt-usuario',
	templateUrl: './usuario.component.html',
	styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {

	user: User;
	userId: Observable<number>;
	userForm: FormGroup;
	passForm: FormGroup;
	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	foto: any;
	foto_upload: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private HeaderService: HeaderService,
		private footerService: FooterService,
		private layoutUtilsService: LayoutUtilsService,
		private subheaderService: SubheaderService,
		private cdRef: ChangeDetectorRef,
		private api: ApiService) {
		this.HeaderService.hide();
		this.footerService.hide();
		this.foto = undefined;
		this.foto_upload = undefined;
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			this.user = new User();
			this.user.clear();
			if (id && id > 0) {
				this.api.willianApi({ modelo: 'usuarios', accion: 'obtener', argumentos: id }).subscribe(res => {
					this.user = Object.assign(this.user, res['respuesta']);
					this.initUser();
					this.foto = this.user.usuario_foto;
				})
			} else {
				this.initUser();
			}
		});
		console.log(this.foto)
	}

	initUser() {
		this.createForm();
		if (!this.user.id_usuario) {
			this.subheaderService.setTitle('Crear usuario');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Usuarios', page: `usuarios` },
				{ title: 'Crear usuario', page: `usuarios/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit user');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Usuarios', page: `usuarios` },
			{ title: 'Editar usuario', page: `usuarios`, queryParams: { id: this.user.id_usuario } }
		]);
	}

	createForm() {
		this.userForm = this.fb.group({
			username: [this.user.username, Validators.required],
			usuario_email: [this.user.usuario_email, [Validators.required, Validators.email]],
			usuario_rol: [this.user.usuario_rol, Validators.required],
			usuario_foto: [this.user.usuario_foto],
			id_usuario: [this.user.id_usuario],
			password: [''],
			repeat_password: ['']
		});
	}

	getComponentTitle() {
		let result = 'Crear usuario';
		if (!this.user || !this.user.id_usuario) {
			return result;
		}

		result = `Editar usuario - ${this.user.username}`;
		return result;
	}

	saveUser() {
		if (this.foto_upload != undefined) {
			const formData = new FormData();
			formData.append("file", this.foto_upload);
			this.api.subirArchivo(formData).subscribe(res => {
				this.guardarUsuario(res.toString());
			});
		} else {
			this.guardarUsuario('');
		}

	}

	guardarUsuario(url: string) {
		if (url !== '') {
			this.userForm.get('usuario_foto').setValue('https://myspanishtaxes.com/back/uploads/' + url);
		}
		const newUser = Object.assign(this.user, this.userForm.value);

		const controls = this.userForm.controls;
		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		if (newUser.id_usuario) {

			if (this.userForm.get('password').value !== '' && this.userForm.get('repeat_password').value !== this.userForm.get('password').value) {
				this.userForm.controls['password'].setErrors({});
				this.userForm.controls['password'].markAsTouched();
				this.userForm.controls['repeat_password'].markAsTouched();
				this.userForm.controls['repeat_password'].setErrors({});

				return;
			} else {
				this.api.apiPanel(
					{
						modelo: 'usuarios',
						accion: 'actualizarContrasennaPanl',
						argumentos: {
							id: newUser.id_usuario,
							pass: this.userForm.get('password').value
						}
					}
				).subscribe();
			}
			this.api.apiPanel({ modelo: 'usuarios', accion: 'actualizar', argumentos: newUser }).subscribe(res => {
				console.log(res['respuesta']); // TODO añadir opción para fallo
				if (res['respuesta']) {
					this.router.navigate(['/panel/usuarios']);
				}
			});
		} else {
			if (this.userForm.get('password').value == '' && this.userForm.get('repeat_password').value == '') {
				this.userForm.controls['password'].setErrors({});
				this.userForm.controls['repeat_password'].setErrors({});
				return;
			} else {
				this.api.apiPanel({ modelo: 'usuarios', accion: 'registrar', argumentos: newUser }).subscribe(res => {
					console.log(res); // TODO añadir opción para fallo
					if (res['respuesta']) {
						this.router.navigate(['/panel/usuarios']);
					}
				});
			}
		}
	}


	preview(files) {
		this.loadingFile = true;
		if (files.length === 0) {
			return;
		}
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();
		//this.clientForm['pic'] = files;
		reader.readAsDataURL(files[0]);

		reader.onload = (_event) => {
			this.loadingFile = false;
			//this.userForm['usuario_foto'] = reader.result;
			this.foto = reader.result;
			this.cdRef.detectChanges();
		}
		this.foto_upload = files[0];
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
