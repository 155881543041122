import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.scss']
})
export class RecuperarPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
