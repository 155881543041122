import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'kt-recomendacion',
  templateUrl: './recomendacion.component.html',
  styleUrls: ['./recomendacion.component.scss']
})
export class RecomendacionComponent implements OnInit {

  recomendacionForm: FormGroup;
  botonMostrado = 0;
  botonMostrado2 = 0;
  modelos = [
    {
      'text': 'Tax form 030',
      'url': '/form/030'
    },
    {
      'text': 'Tax form 210',
      'url': '/form/210'
    },
    {
      'text': 'Tax form 210',
      'url': '/form/210'
    },
    {
      'text': 'Tax form 720',
      'url': '/form/720'
    },
    {
      'text': 'Tax form 714',
      'url': '/form/714'
    },
    {
      'text': 'Tax form 100',
      'url': '/form/100'
    },
    {
      'text': 'Contac us for more information',
      'url': '/contact'
    }
  ]

  constructor(private fb: FormBuilder) {}

  ngOnInit() {

    this.recomendacionForm = this.fb.group({
      pregunta1: 0,
      pregunta2: 0,
      pregunta3: 0,
      pregunta4: 0,
      pregunta5: 0,
      pregunta6: 0,
      pregunta7: 0,
      pregunta8: 0,
      pregunta9: 0,
      pregunta10: 0,
      pregunta11: 0,
      pregunta12: 0,
      pregunta13: 0,
      pregunta14: 0
    });

  }

  mostrarElementos(): void {
    this.botonMostrado = 0;
    /*if(this.recomendacionForm.value['pregunta1'] == 2) {
      this.botonMostrado = 1;
    } else if(this.recomendacionForm.value['pregunta1'] == 1) {*/
        if(this.recomendacionForm.value['pregunta2'] == 2) {
          
          if(this.recomendacionForm.value['pregunta3'] == 1) {
            if(this.recomendacionForm.value['pregunta4'] == 1) {
              this.botonMostrado = 2;
            }else if(this.recomendacionForm.value['pregunta4'] == 2) {
            this.botonMostrado = 3;
            }else{
              this.botonMostrado = 0;
            }
          }else if(this.recomendacionForm.value['pregunta3'] == 2) {
            if(this.recomendacionForm.value['pregunta5'] == 2) {
              if(this.recomendacionForm.value['pregunta6'] == 2) {
                if(this.recomendacionForm.value['pregunta7'] == 2) {
                  this.botonMostrado = 7;
                }
              }
            }
          }

          if(this.recomendacionForm.value['pregunta5'] == 1 || this.recomendacionForm.value['pregunta6'] == 1 || this.recomendacionForm.value['pregunta7'] == 1){
            this.botonMostrado = 2;
          }
        } else if(this.recomendacionForm.value['pregunta2'] == 1) {
          
          if(this.recomendacionForm.value['pregunta8'] == 1) {
            this.botonMostrado = 4;
            this.botonMostrado2 = 6;
          }else{
            this.botonMostrado = 0;
          }

          if(this.recomendacionForm.value['pregunta9'] == 1 || this.recomendacionForm.value['pregunta10'] == 1 || this.recomendacionForm.value['pregunta11'] == 1 || this.recomendacionForm.value['pregunta12'] == 1 ||this.recomendacionForm.value['pregunta13'] == 1) {
            this.botonMostrado = 6;
          }

          if(this.recomendacionForm.value['pregunta14'] == 1){
            this.botonMostrado = 5;
            this.botonMostrado2 = 6;
          }

          if( this.recomendacionForm.value['pregunta8'] == 2 && this.recomendacionForm.value['pregunta9'] == 2 &&  this.recomendacionForm.value['pregunta10'] == 2 &&  this.recomendacionForm.value['pregunta11'] == 2 &&  this.recomendacionForm.value['pregunta12'] == 2 && this.recomendacionForm.value['pregunta13'] == 2 && this.recomendacionForm.value['pregunta14'] == 2){
            this.botonMostrado = 7;
          }

        }
      
    /*} else{
      this.botonMostrado = 0;
    }*/
    
  }

  resetSelects(item: string): void {
    let breakPoint = false;
    Object.keys(this.recomendacionForm.controls).forEach(key => {
      //console.log(key);
      if(breakPoint) {
        this.recomendacionForm.controls[key].setValue(0);
      }
      if(key == item) {
        breakPoint = true;
      }
    })
  }

  onFormChanges(event) {
    this.resetSelects(event.target.id);
    this.mostrarElementos();
  }

}
