// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClienteComponent } from './clientes/cliente/cliente.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { DocumentoComponent } from './documentos/documento/documento.component';
import { DeleteUserComponent } from './usuarios/usuario/delete-user/delete-user.component';
import { DeleteClienteComponent } from './clientes/cliente/delete-cliente/delete-cliente.component';
import { UpdatePasswordComponent } from "../../pages/panel-usuario/update-password/update-password.component";
// Material
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { SolicitudComponent } from './solicitudes/solicitud/solicitud.component';
import { DeleteSolicitudComponent } from './solicitudes/solicitud/delete-solicitud/delete-solicitud.component';
import { from } from 'rxjs';

@NgModule({
	declarations: [
		UsuariosComponent,
		UsuarioComponent,
		ClientesComponent,
		ClienteComponent,
		DocumentosComponent,
		DocumentoComponent,
		DeleteUserComponent,
		DeleteClienteComponent,
		UpdatePasswordComponent,
		SolicitudesComponent,
		SolicitudComponent,
		DeleteSolicitudComponent
	],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		RouterModule,
		MatIconModule,
		MatButtonModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatMenuModule,
		MatTabsModule,
		MatProgressBarModule,
		MatDialogModule,
		MatDatepickerModule,
		MatRadioModule,
		MatProgressSpinnerModule
	],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
	entryComponents: [DeleteUserComponent, DeleteClienteComponent, DeleteSolicitudComponent,UpdatePasswordComponent]
})
export class PagesModule {
}
