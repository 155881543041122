export class Model {
    id_modelo: number;
    modelo_nombre: string;
    model_code: string;
    clear() {
        this.id_modelo = undefined;
        this.modelo_nombre = '';
        this.model_code = '';
    }
}
