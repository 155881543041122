// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	constructor(private store: Store<AppState>) {
	}

	ngOnInit(): void {
		this.user$ = JSON.parse(localStorage.getItem('currentUser'));
	}

	logout() {
		this.store.dispatch(new Logout());
	}

	setDefaultPic() {
		this.user$.pic = '../../../../../../assets/media/users/default.jpg';
	}
}
