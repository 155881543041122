export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{section: 'Usuarios'},
				{
					title: 'Usuarios',
					root: true,
					icon: 'flaticon2-user',
					page: 'usuarios',
					bullet: 'dot',
				},
				{
					title: 'Clientes',
					root: true,
					icon: 'flaticon2-user',
					page: 'clientes',
					bullet: 'dot',
				},
				{
					title: 'Solicitudes',
					root: true,
					icon: 'flaticon-signs-1',
					page: 'solicitudes',
					bullet: 'dot',
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
