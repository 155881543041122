import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'kt-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  form_errors: boolean;
  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private loginService: LoginService, private cdRef: ChangeDetectorRef) {
    this.form_errors = false;
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      cuerpo: ['', [Validators.required]]
    });
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        this.contactForm.get('name').setValue(res['respuesta']['person_full_name'].split(' ')[0]);
        this.contactForm.get('surname').setValue(res['respuesta']['person_full_name'].split(' ')[1]);
        this.contactForm.get('email').setValue(res['respuesta']['usuario_email']);
        this.contactForm.get('phone').setValue(res['respuesta']['person_tel1']);
        this.cdRef.detectChanges();
      });
    }
  }
  enviarFormularioContacto() {
    if (this.contactForm.valid) {
      this.api.willianApi({ accion: 'FormularioContacto', argumentos: this.contactForm.value }).subscribe(res => {
        if (res['respuesta']) {
          this.router.navigate(['/thank-you'])
        }
      })
    }
    else {
      this.form_errors = true;
      return;
    }
  }
}
