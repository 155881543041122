import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
declare var $: any;

@Component({
  selector: 'kt-formulario714',
  templateUrl: './formulario714.component.html',
  styleUrls: ['./formulario714.component.scss']
})
export class Formulario714Component implements OnInit {

  modelo714Form: FormGroup;
  arr1: boolean[] = [];
  situacionAsistente = 0;
  loaded: boolean;
  form_errors: boolean;
  paises:object[];
  form_array_errors: boolean;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService
  ) {
    this.form_errors = false;
    this.loaded = false;
    this.form_array_errors = false;
    this.paises=[];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
  }

  ngOnInit() {
    this.inicializarFormulario();
    this.arr1 = [true, false, false, false];
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modelo714Form.get('DNI').setValue(res['respuesta']['person_dni']);
        this.modelo714Form.get('nombreORS').setValue(res['respuesta']['person_full_name']);
        this.modelo714Form.get('estadoCivil').setValue(res['respuesta']['person_estado_civil']);
        this.cdRef.detectChanges();
      });
    }
  }

  inicializarFormulario() {
    this.modelo714Form = this.fb.group({
      //vista 1
      DNI: ["", [Validators.required]],
      nombreORS: ["", [Validators.required]],
      genero: [""],
      fechaNacimiento25: ["", [Validators.required]],
      estadoCivil: ["", [Validators.required]],
      discapacidad: [""],
      disability_type: [""],

      //vista 2
      home_location: ["", [Validators.required]],
      tipoVia2: [""],
      nombreVia2: [""],
      tipoEnumeracion2: [""],
      numeroVia2: [""],
      calificadorNum2: [""],
      bloque2: [""],
      portal2: [""],
      escalera2: [""],
      planta2: [""],
      puerta2: [""],
      localidad2: [""],
      provincia2: [""],
      codigoPostal2: [""],
      municipio2: [""],
      domicilioExtranjero: [""],
      poblacionExtranjero: [""],
      codPostalExtranjero: [""],
      provinciaExtranjero: [""],
      paisExtranjero: [""],

      //vista 3 
      ckbox_declaration_complementary: "",
      bienes: new FormArray([]),
      cuentas: new FormArray([]),
      acciones: new FormArray([]),

      //vista 4
      tipoPago: [""],
      cuentaBancaria: ["", [Validators.required]],
      comentarios: [""],

      id_usuario: [""]
    });
    if (this.loginService.devolver()) {
      this.modelo714Form.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }

  anteriorPag(): void {
    if (this.situacionAsistente > 0) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente--;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }
  }

  siguientePag(): void {
    if (this.situacionAsistente < 8) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente++;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }
  }

  guardarFormPrincipal(): void {
    if (this.modelo714Form.valid) {
      this.api.willianApi({ accion: "GuardarModelo714", argumentos: this.modelo714Form.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      }, error => {
        console.log(error);
      });
      this.form_errors = false;
    } else {
      var page = this.buscarPaginaPrimerError();
      console.log(page);
      if (page !== -1) {
        this.situacionAsistente = page;
        for (let index = 0; index < this.arr1.length; index++) {
          if (page === index) {
            this.arr1[index] = true;
          } else {
            this.arr1[index] = false;
          }
        }
      }
      this.form_errors = true;
      return;
    }
  }

  buscarPaginaPrimerError() {
    var page = -1;
    var cuenta = 0;
    if (
      this.modelo714Form.controls['DNI'].errors ||
      this.modelo714Form.controls['nombreORS'].errors ||
      this.modelo714Form.controls['fechaNacimiento25'].errors ||
      this.modelo714Form.controls['estadoCivil'].errors
    ) { page = 0; cuenta++; } else if (
      (this.modelo714Form.controls['home_location'].errors) && cuenta === 0
    ) { page = 1; cuenta++; } else if (
      (this.modelo714Form.controls['cuentaBancaria'].errors) && cuenta === 0
    ) { page = 3; cuenta++; }
    return page;
  }

  /* bienes */
  anadirBien() {
    for (let control of this.modelo714Form.get('bienes')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      claveTipo: [""],
      porcentajeProp: [""],
      ValueUsed: [""],
      SituationKey: [""],
      valoration: [""],
      domicilioEntidad: [""],
      poblacionEntidad: [""],
      provinciaEntidad: [""],
      ZipCodeEntidad: [""],
      codPaisEntidad: [""],
      valoracion1: [""],
      porcPart: [""],
      saved: 0
    });

    const bienes = this.modelo714Form.get('bienes') as FormArray;
    bienes.push(group);
    this.modelo714Form.controls['bienes'] = bienes;
    this.cdRef.detectChanges();
  }

  guardarFormBienes(i: number): void {
    const bien = (<FormArray>this.modelo714Form.get('bienes')).at(i);
    if (bien.valid) {
      bien.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormBienes(i: number): void {
    const bien = (<FormArray>this.modelo714Form.get('bienes')).at(i);
    bien.patchValue({ saved: 0 });
  }

  eliminarFormBienes(i: number): void {
    (<FormArray>this.modelo714Form.get('bienes')).removeAt(i);
  }

  /* cuentas */
  anadirCuenta() {
    for (let control of this.modelo714Form.get('cuentas')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      claveTipo: [""],
      porcentajeProp: [""],
      values: [""],
      isin: [""],
      valoration: [""],
      saved: 0
    });

    const cuentas = this.modelo714Form.get('cuentas') as FormArray;
    cuentas.push(group);
    this.modelo714Form.controls['cuentas'] = cuentas;
    this.cdRef.detectChanges();
  }

  guardarFormCuentas(i: number): void {
    const cuentas = (<FormArray>this.modelo714Form.get('cuentas')).at(i);
    if (cuentas.valid) {
      cuentas.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormCuentas(i: number): void {
    const cuentas = (<FormArray>this.modelo714Form.get('cuentas')).at(i);
    cuentas.patchValue({ saved: 0 });
  }

  eliminarFormCuentas(i: number): void {
    (<FormArray>this.modelo714Form.get('cuentas')).removeAt(i);
  }

  /* acciones */
  anadirAccion() {
    for (let control of this.modelo714Form.get('acciones')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      claveTipo: [""],
      porcentajeProp: [""],
      values: [""],
      isin: [""],
      valoration: [""],
      saved: 0
    });

    const acciones = this.modelo714Form.get('acciones') as FormArray;
    acciones.push(group);
    this.modelo714Form.controls['acciones'] = acciones;
    this.cdRef.detectChanges();
  }

  guardarFormAcciones(i: number): void {
    const acciones = (<FormArray>this.modelo714Form.get('acciones')).at(i);
    if (acciones.valid) {
      acciones.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormAcciones(i: number): void {
    const acciones = (<FormArray>this.modelo714Form.get('acciones')).at(i);
    acciones.patchValue({ saved: 0 });
  }

  eliminarFormAcciones(i: number): void {
    (<FormArray>this.modelo714Form.get('acciones')).removeAt(i);
  }

}
