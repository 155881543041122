import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  usr_logged: boolean;
  name: string;

  constructor() {
    /*  if (localStorage.length > 0 && localStorage.getItem('id_usuario') !== "undefined" && localStorage.getItem('email') !== "undefined"
     && localStorage.getItem('id_usuario') !== null && localStorage.getItem('email') !== null) {
       this.usr_logged = true;
       this.name=localStorage.getItem('nombre');
     } else {
     } */

    this.usr_logged = false;
    this.name = '';
  }

  setTrue() { this.usr_logged = true; }

  setFalse() { this.usr_logged = false; }

  devolver() { return this.usr_logged; }

  toggle() { this.usr_logged = !this.usr_logged; }

  setName(nombre: string) {
    this.name = nombre;
  }
  getName() {
    return this.name;
  }
}



