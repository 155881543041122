// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// /SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
//import { HeadComponent } from './pages/includes/head/head.component';
import { HeaderComponent } from './pages/includes/header/header.component';
import { FooterComponent } from './pages/includes/footer/footer.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
// Layout Services
import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from './core/_base/layout';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthService } from './core/auth';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import { MainComponent } from './pages/main/main.component';
// API Service
import { ApiService } from './services/api/api.service';
import { PreciosComponent } from './pages/precios/precios.component';
//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormularioIrpfComponent } from './pages/formularios/formulario-irpf/formulario-irpf.component';
//Angular material
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

//Pages
import { Formulario720Component } from './pages/formularios/formulario720/formulario720.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { LoginComponent } from './pages/login/login.component';
import { PagoRealizadoComponent } from './pages/pago-realizado/pago-realizado.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { ContactComponent } from './pages/contact/contact.component';
import { Formulario210Component } from './pages/formularios/formulario210/formulario210.component';
import { Formulario100Component } from './pages/formularios/formulario100/formulario100.component';
import { Formulario714Component } from './pages/formularios/formulario714/formulario714.component';
import { FreelanceComponent } from './pages/formularios/freelance/freelance.component';
import { HeadComponent } from './pages/includes/head/head.component'
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { RecomendacionComponent } from './pages/recomendacion/recomendacion.component';
import { PagoComponent } from './pages/pago/pago.component';
import { Formulario030Component } from './pages/formularios/formulario030/formulario030.component';
import { PanelUsuarioComponent } from './pages/panel-usuario/panel-usuario.component';
import {UpdateUserComponent} from './pages/panel-usuario/update-user/update-user.component';
import { BeckhamLawComponent } from './pages/formularios/beckham-law/beckham-law.component';
import { SpecialTaxComponent } from './pages/formularios/special-tax/special-tax.component';

//Directives
import { BeckhamLawDadDirective } from './pages/formularios/beckham-law/beckham-law-dad.directive';
import { DragAndDropDirective } from './pages/directives/drag-and-drop.directive';
import { RecuperarPasswordComponent } from './pages/recuperar-password/recuperar-password.component';
import { CambiarPasswordComponent } from './pages/cambiar-password/cambiar-password.component'
import { PagoFallidoComponent } from './pages/pago/pago-fallido/pago-fallido.component';
import { FacturaComponent } from './pages/factura/factura.component';


import { LoginService } from './services/login/login.service';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { LegalNoticesComponent } from './pages/legal-notices/legal-notices.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { ActivarUsuarioComponent } from './pages/activar-usuario/activar-usuario.component';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{name: 'typescript', func: typescript},
		{name: 'scss', func: scss},
		{name: 'xml', func: xml},
		{name: 'json', func: json}
	];
}

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		AppComponent,
		MainComponent,
		PreciosComponent,
		FormularioIrpfComponent,
		Formulario720Component,
		FaqsComponent,
		LoginComponent,
		PagoRealizadoComponent,
		RegistroComponent,
		ContactComponent,
		Formulario210Component,
		Formulario100Component,
		HeadComponent,
		Formulario714Component,
		RecomendacionComponent,
		PagoComponent,
		Formulario030Component,
		PanelUsuarioComponent,
		UpdateUserComponent,
		BeckhamLawComponent,
		FreelanceComponent,
		SpecialTaxComponent,
		BeckhamLawDadDirective,
		ReviewsComponent,
		DragAndDropDirective,
		RecuperarPasswordComponent,
		CambiarPasswordComponent,
		PagoFallidoComponent,
		FacturaComponent,
		ThanksComponent,
		PrivacyPolicyComponent,
		TermsConditionsComponent,
		LegalNoticesComponent,
		DisclaimerComponent,
		ActivarUsuarioComponent
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
			passThruUnknownUrl: true,
			dataEncapsulation: false
		}) : [],
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, {metaReducers}),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,
		FontAwesomeModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		MatRippleModule,
		MatInputModule,
		MatRadioModule,
		ReactiveFormsModule,
		FormsModule,
		MatTooltipModule
	],
	exports: [],
	providers: [
		AuthService,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService], multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {languages: hljsLanguages}
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		LoginService,
		ApiService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
