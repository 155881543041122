import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	private msgPaymentError = new BehaviorSubject('Payment could not be processed');
	currentMessage = this.msgPaymentError.asObservable();

	constructor() { }

	changeErrorPaymentMessage(message: string) {
		this.msgPaymentError.next(message)
	}

}
