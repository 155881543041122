import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { User } from './user.model';

export class Client extends BaseModel {
    id_person: number;
    person_dni: string;
    person_full_name: string;
    person_birthdate: string;
    person_gender: number;
    person_tel1: string;
    person_tel2: string;
    birthdate: string;
    bank_account: string;
    civil_status: number;
    address: Address;
    disability_key: number;
    usuario:User;

    clear(): void {
        this.id_person = undefined;
        this.person_dni = '';
        this.person_full_name = '';
        this.person_birthdate = '';
        this.person_gender = undefined;
        this.person_tel2 = '';
        this.birthdate = '';
        this.bank_account = '';
        this.birthdate = '';
        this.address = new Address();
        this.disability_key = undefined;
        this.usuario=new User();
    }
}
