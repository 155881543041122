import { BaseModel } from '../../_base/crud';
import { Client } from './client.model';
import { Model } from './model.model';
import { SocialNetworks } from './social-networks.model';
import { User } from './user.model';

export class Solicitud extends BaseModel {
    id_relacion_modelo: number;
    type_declaration: number;
    comentarios: string;
    fecha_creacion: string
    paid_out: number;
    modelo: Model;
    cliente: Client;

    clear(): void {
        this.id_relacion_modelo = undefined;
        this.type_declaration = undefined;
        this.comentarios = '';
        this.fecha_creacion = '';
        this.paid_out = undefined;
        this.modelo = new Model();
        this.cliente = new Client();
    }
}
