export class Pais {
    id_pais: number;
    pais_nombre: string;
    pais_code: string;
    pais_value: number;

    clear() {
        this.id_pais = undefined;
        this.pais_nombre = '';
        this.pais_code = '';
        this.pais_value = undefined;
    }
}
