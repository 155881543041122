import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Client } from '../../../../core/auth/_models/client.model';
import { Observable } from 'rxjs';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { SubheaderService } from '../../../../core/_base/layout';

@Component({
	selector: 'kt-cliente',
	templateUrl: './cliente.component.html',
	styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit, OnDestroy {

	client: Client;
	clientId: Observable<number>;

	clientForm: FormGroup;
	addressForm: FormGroup;

	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	foto: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
		this.foto = undefined;
	}

	ngOnInit() {

		this.createForm();
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			this.client = new Client();
			this.client.clear();
			this.client.address.clear();
			//console.log(id)
			if (id && id > 0) {
				this.api.apiPanel(
					{
						modelo: 'persons',
						accion: 'ObtenerDatosCliente',
						argumentos: id
					}
				).subscribe((res) => {
					this.clientForm.get('person_full_name').setValue(res['respuesta']['direccion_calle']);
					this.clientForm.get('person_tel1').setValue(res['respuesta']['person_tel1']);
					this.clientForm.get('person_tel2').setValue(res['respuesta']['person_tel2']);
					this.clientForm.get('birthdate').setValue(res['respuesta']['birthdate']);
					this.clientForm.get('civil_status').setValue(res['respuesta']['person_estado_civil']);
					this.clientForm.get('usuario_email').setValue(res['respuesta']['usuario_email']);

					this.addressForm.get('direccion_provincia').setValue(res['respuesta']['direccion_provincia']);
					this.addressForm.get('direccion_pueblo').setValue(res['respuesta']['direccion_pueblo']);
					this.addressForm.get('direcion_codigo_postal').setValue(res['respuesta']['direcion_codigo_postal']);
					this.addressForm.get('direccion_calle').setValue(res['respuesta']['direccion_calle']);
					this.addressForm.get('direccion_extra').setValue(res['respuesta']['direccion_extra']);
					this.addressForm.get('pais_nombre').setValue(res['respuesta']['pais_nombre']);
				})
			} else {
				this.router.navigate(['/panel/usuarios']);
			}
		});
	}

	initUser() {
		if (!this.client.id_person) {
			this.subheaderService.setTitle('Crear cliente');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Clientes', page: `clientes` },
				{ title: 'Crear cliente', page: `clientes/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit client');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Clientes', page: `clientes` },
			{ title: 'Editar cliente', page: `clientes`, queryParams: { id: this.client.id_person } }
		]);
	}

	createForm() {
		this.clientForm = this.fb.group({
			person_full_name: ['', Validators.required],
			person_tel1: ['', Validators.required],
			person_tel2: ['', Validators.required],
			birthdate: ['', Validators.required],
			civil_status: ['', Validators.required],
			usuario_email: ['']
		});

		this.addressForm = this.fb.group({
			direccion_provincia: [''],
			direccion_pueblo: [''],
			direcion_codigo_postal: [''],
			direccion_calle: [''],
			direccion_extra: [''],
			pais_nombre: ['']
		});
	}

	getComponentTitle() {
		let result = 'Crear cliente';
		if (!this.client || !this.client.id_person) {
			return result;
		}
		result = `Editar cliente - ${this.client.person_full_name}`;
		return result;
	}

	saveUser() {
		const newClient = Object.assign(this.client, this.clientForm.value);
		if (newClient.address) {
			newClient.address = Object.assign(this.client.address, this.addressForm.value);
		}
		delete newClient.accessToken;

		if (newClient.id) {
			/* this.api.updateData('clientes', newClient).subscribe(res => {
				console.log(res); // TODO añadir opción para fallo
				if(res) {
					this.router.navigate(['/panel/clientes']);
				}
			}); */
		} else {
			newClient.password = newClient.email;
			/* this.api.createData('clientes', newClient).subscribe(res => {
				console.log(res); // TODO añadir opción para fallo
				if(res) {
					this.router.navigate(['/panel/clientes']);
				}
			}); */
		}
	}

	preview(files) {
		this.loadingFile = true;
		if (files.length === 0) {
			return;
		}
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();
		//this.clientForm['pic'] = files;
		reader.readAsDataURL(files[0]);
		reader.onload = (_event) => {
			//this.clientForm['pic'] = reader.result;
			this.loadingFile = false;
		}
	}

	isControlHasError(controlName: string, validationType: string, formulario: FormGroup): boolean {
		const control = formulario.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	updateUrl(event) {
		event.target.src = '../../../../../assets/img/users/default.jpg';
	}

	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}
