import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'kt-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.scss']
})
export class CambiarPasswordComponent implements OnInit {
  passwordchangeForm: FormGroup;

  constructor(private fb: FormBuilder, private api: ApiService, private router: Router) { }

  ngOnInit() {
    this.passwordchangeForm = this.fb.group({
      email: ""
    });
  }

  cambiarPassword() {
    this.api.willianApi({ accion: 'RecordarPassword', argumentos: this.passwordchangeForm.value }).subscribe(res => {

      if (res['respuesta']) {
        this.router.navigate(['/thank-you']);
      } else {
        console.log(res['respuesta']);
      }
    });
  }
}
