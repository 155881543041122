import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
declare var $: any;

@Component({
  selector: 'kt-formulario210',
  templateUrl: './formulario210.component.html',
  styleUrls: ['./formulario210.component.scss']
})

export class Formulario210Component implements OnInit {

  modelo210Form: FormGroup;
  arr1: boolean[] = [];
  loaded: boolean;
  paises: object[];
  situacionAsistente = 0;
  form_errors: boolean;
  fragment: string;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private ruta: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService
  ) {
    this.loaded = false;
    this.form_errors = false;
    this.modelo210Form = this.fb.group({
      /* primera vista */
      nif1: ["", [Validators.required]],
      name1: ["", [Validators.required]],
      tipoRenta: ["", [Validators.required]],
      claveDivisas: ["", [Validators.required]],
      periodoDeclarante: ["", [Validators.required]],
      ejercicioDevengo: ["", [Validators.required]],
      fechaDevengo: ["", [Validators.required]],
      /* segunda vista */
      nifContribuyente: ["", [Validators.required]],
      fj: [""],
      nombreContribuyente: ["", [Validators.required]],
      nifResidencia: ["", [Validators.required]],
      fechaIncorpEntidad: ["", [Validators.required]],
      lugarNacimiento: ["", [Validators.required]],
      paisContribuyente: ["", [Validators.required]],
      residenciaContribuyente: ["", [Validators.required]],

      /* <<inicio tercerea vista */
      domicilioResidencia: ["", [Validators.required]],
      poblacionResidencia: ["", [Validators.required]],
      zipResidencia: ["", [Validators.required]],
      provinciaResidencia: ["", [Validators.required]],
      paisResidencia: ["", [Validators.required]],

      /* *ngIf="modelo210Form.value['tipoRenta'] == 'o4GMmj' || modelo210Form.value['tipoRenta'] == 'o4GMoj'" */
      tipoViaSituacion1: [""],
      viaSituacion1: [""],
      enumeracionSituacion1: [""],
      numeroSituacion1: [""],
      calificadorSituacion1: [""],
      bloqueSituacion1: [""],
      portalSituacion1: [""],
      escaleraSituacion1: [""],
      plantaSituacion1: [""],
      puertaSituacion1: [""],
      localidadSituacion1: [""],
      provinciaSituacion1: [""],
      municipioSituacion1: [""],
      cpSituacion1: [""],
      catastroSituacion1: [""],

      /* *ngIf="modelo210Form.value['tipoRenta'] == 'o4GMmj' || modelo210Form.value['tipoRenta'] == 'o4GMnj'" */
      rendimientosSituacion2: [""],
      gastosSituacion2: [""],
      baseSituacion2: [""],

      /*  *ngIf="modelo210Form.value['tipoRenta'] == 'o4GMoj'" */
      baseSituacion3: [""],
      porcentaje: [""],
      revisado: [""],
      periodocomputable: [""],
      /* fin tercera vista>> */

      /* ultima vista */
      tipoPago: [""],
      cuentaBancaria: ["", [Validators.required]],
      comentarios: [""],
      id_usuario: [""]

      /* por revisar 
      exencionSituacion2: [""],
      porcentajeConvenio: [""],
      complementResidencia: "",
      emailResidencia: "",
      fijoResidencia: "",
      movilResidencia: "",
      faxResidencia: "",
      nifRepresentante: "",
      fjRepresentante: "",
      nombreRepresentante: "",
      tipoRepresentante: "",
      tipoVia: "",
      nombreVia: "",
      numeracionVia: "",
      numeroVia: "",
      calificadorNumero: "",
      bloqueVia: "",
      portalVia: "",
      escaleraVia: "",
      plantaVia: "",
      puertaVia: "",
      complementRepresentante: "",
      localidadRepresentante: "",
      provinciaRepresentante: "",
      municipioRepresentante: "",
      cpRepresentante: "",
      fijoRepresentante: "",
      movilRepresentante: "",
      faxRepresentante: "",
      nifPagador: "",
      fjPagador: "",
      nombrePagador: "",
      complementSituacion1: "",
      coSituacion4: "",
      contribuyenteSituacion4: "",
      conyugeSituacion4: "",
      nifConyuge: "",
      nombreConyuge: "",
      valorTransmision: "",
      valorTransmision2: "",
      valorAdquisicion: "",
      valorAdquisicion2: "",
      diferencia1: "",
      diferencia2: "",
      telefono: "",
      ganancia2: "",
      baseConyuge: "",
      fechaAdqui: "",
      fechaMej: "",
      justificante211: "",
      gravamen: "",
      cuotaIntegra: "",
      deduccionDonativos: "",
      cuotaley: "",
      limiteConvenio: "",
      reduccionConvenio: "",
      cuotaIntRed: "",
      retencionesCuenta: "",
      ingresoDev: "",
      resultadoLiquidacion: "",
      numJustificante: "",
      baseSituacion5: "",*/
    });
    this.paises=[];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
  }

  ngOnInit() {
    this.arr1 = [true, false, false, false];
    if (this.loginService.devolver()) {
      this.modelo210Form.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modelo210Form.get('nif1').setValue(res['respuesta']['person_dni']);
        this.modelo210Form.get('name1').setValue(res['respuesta']['person_full_name']);
        this.cdRef.detectChanges();
      });
    }
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  anteriorPag(): void {
    if (this.situacionAsistente > 0) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente--;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }
  }

  siguientePag(): void {
    if (this.situacionAsistente < 8) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente++;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();

    }
  }

  guardarFormPrincipal(): void {
    if (this.modelo210Form.valid) {
      console.log(this.modelo210Form.value);
      this.api.willianApi({ accion: 'GuardarModelo210', argumentos: this.modelo210Form.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      }, error => {
        console.log(error);
      });
      this.form_errors = false;
    } else {
      console.log(this.modelo210Form.errors);
      var page = this.buscarPaginaPrimerError();
      if (page !== -1) {
        this.situacionAsistente = page;
        for (let index = 0; index < this.arr1.length; index++) {
          if (page === index) {
            this.arr1[index] = true;
          } else {
            this.arr1[index] = false;
          }
        }
      }
      this.form_errors = true;
      return;
    }
  }

  buscarPaginaPrimerError() {
    var page = -1;
    var cuenta = 0;
    if (
      this.modelo210Form.controls['nif1'].errors ||
      this.modelo210Form.controls['name1'].errors ||
      this.modelo210Form.controls['tipoRenta'].errors ||
      this.modelo210Form.controls['claveDivisas'].errors ||
      this.modelo210Form.controls['periodoDeclarante'].errors ||
      this.modelo210Form.controls['ejercicioDevengo'].errors ||
      this.modelo210Form.controls['fechaDevengo'].errors
    ) { page = 0; cuenta++; } else if (
      (this.modelo210Form.controls['nifContribuyente'].errors ||
        //(this.modelo210Form.controls['fj'].errors && this.modelo210Form.value['fj'] == '0') ||
        this.modelo210Form.controls['nombreContribuyente'].errors ||
        this.modelo210Form.controls['nifResidencia'].errors ||
        this.modelo210Form.controls['fechaIncorpEntidad'].errors ||
        this.modelo210Form.controls['lugarNacimiento'].errors ||
        this.modelo210Form.controls['paisContribuyente'].errors ||
        this.modelo210Form.controls['residenciaContribuyente'].errors) && cuenta === 0
    ) { page = 1; cuenta++; } else if (
      (this.modelo210Form.controls['domicilioResidencia'].errors ||
        this.modelo210Form.controls['poblacionResidencia'].errors ||
        this.modelo210Form.controls['zipResidencia'].errors ||
        this.modelo210Form.controls['provinciaResidencia'].errors ||
        this.modelo210Form.controls['paisResidencia'].errors) && cuenta === 0
    ) { page = 2; } else if (
      (//this.modelo210Form.controls['tipoPago'].errors ||
        this.modelo210Form.controls['cuentaBancaria'].errors) && cuenta === 0
    ) { page = 3; }
    return page;
  }

}
