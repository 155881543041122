import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../services/data/data.service';

@Component({
	selector: 'kt-pago-fallido',
	templateUrl: './pago-fallido.component.html',
	styleUrls: ['./pago-fallido.component.scss']
})
export class PagoFallidoComponent implements OnInit {

	msg: string

	constructor(
		private dataService: DataService
	) { }

	ngOnInit() {
		this.dataService.currentMessage.subscribe( msg => console.log(this.msg = msg) )
	}

	tryAgain() {
		history.back()
	}

}
