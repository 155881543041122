import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
declare var $: any;

@Component({
  selector: 'kt-formulario030',
  templateUrl: './formulario030.component.html',
  styleUrls: ['./formulario030.component.scss']
})
export class Formulario030Component implements OnInit {

  modelo030Form: FormGroup;
  arr1: boolean[] = [];

  paises: object[];
    situacionAsistente = 0;
  loaded: boolean;
  form_errors: boolean;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService
  ) {
    this.loaded = false;
    this.form_errors = false;
    this.paises=[];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
  }

  ngOnInit() {
    this.iniciarFormulario();
    this.arr1 = [true, false, false, false, false, false, false, false, false];
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modelo030Form.get('idInput').setValue(res['respuesta']['person_dni']);
        this.modelo030Form.get('nombre').setValue(res['respuesta']['person_full_name'].split(' ')[0]);
        this.modelo030Form.get('apellidos').setValue(res['respuesta']['person_full_name'].split(' ')[1]);
        this.cdRef.detectChanges();
      });
    }
  }


  iniciarFormulario() {
    this.modelo030Form = this.fb.group({
      //parte 1
      idInput: "",
      nombre: "",
      apellidos: "",
      //parte 2
      cause11: "",
      cause12: "",
      cause21: "",
      cause22: "",
      cause31: "",
      cause32: "",
      cause41: "",
      cause42: "",
      cause51: "",
      cause52: "",
      cause61: "",
      cause62: "",
      cause71: "",
      cause72: "",
      cause81: "",
      cause82: "",
      //parte 3
      tipoResidente: ["", Validators.required],
      nifOtroInteresado: ["", Validators.required],
      pasaporteInteresado: ["", Validators.required],
      fechaResidenciaFiscal: ["", Validators.required],
      nacionalidadInteresado: ["", Validators.required],
      genero: [""],
      nifInteresado: "",
      primerApellido: "",
      segundoApellido: "",
      nombreInteresado: "",
      fechaNacimiento: "",
      lugarNacimiento: "",
      provinciaNacimiento: "",
      municipioNacimiento: "",
      //parte 4
      tipoResidenteConyuge: "",
      nifConyuge: "",
      pasaporteConyuge: "",
      fechaResidenciaFiscalConyuge: "",
      nacionalidadConyuge: "",
      genero2: "",
      nifConyuge2: "",
      primerApellidoConyuge: "",
      segundoApellidoConyuge: "",
      nombreConyuge: "",
      fechaNacimientoConyuge: "",
      lugarNacimientoConyuge: "",
      provinciaConyuge: "",
      municipioConyuge: "",
      /* parte 5 */
      prefijoPais: "",
      phone: ["", Validators.required],
      email: ["", Validators.required],
      mobile_cancelation: "",
      email_unsubscribe: "",
      //parte 6
      tipoVia1: "",
      nombreVia1: "",
      tipoEnumeracion1: "",
      numeroVia1: "",
      calificadorNum1: "",
      bloque1: "",
      portal1: "",
      escalera1: "",
      planta1: "",
      puerta1: "",
      localidad1: "",
      provincia1: "",
      codigoPostal1: "",
      catastral1: "",
      refCatastral1: "",
      /* parte 7 */
      catastral2: "",
      domicilioExtranjero: "",
      poblacionExtranjero: "",
      codPostalExtranjero: "",
      provinciaExtranjero: "",
      paisExtranjero: "",
      //parte 8
      unsubscribe_address_service: "",
      tipoVia2: "",
      nombreVia2: "",
      tipoEnumeracion2: "",
      numeroVia2: "",
      calificadorNum2: "",
      bloque2: "",
      portal2: "",
      escalera2: "",
      planta2: "",
      puerta2: "",
      localidad2: "",
      provincia2: "",
      codigoPostal2: "",
      destinatario2: "",
      apartadoCorreos: "",
      localidad3: "",
      codigoPostal3: "",
      provincia3: "",
      destinatario3: "",
      //parte 9
      marital_status: ["", Validators.required],
      fechaNuevoEstado: "",
      comentarios: "",
      id_usuario: ""
    });
    if (this.loginService.devolver()) {
      this.modelo030Form.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }

  guardarFormPrincipal() {
    if (this.modelo030Form.valid) {
      this.api.willianApi({ accion: 'guardarModelo030', argumentos: this.modelo030Form.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      }, error => {
        console.log(error);
      });

    } else {
      var page = this.buscarPaginaPrimerError();
      if (page !== -1) {
        this.situacionAsistente = page;
        for (let index = 0; index < this.arr1.length; index++) {
          if (page === index) {
            this.arr1[index] = true;
          } else {
            this.arr1[index] = false;
          }
        }
      }
      this.form_errors = true;
      return;
    }
  }

  anteriorPag(): void {
    if (this.situacionAsistente > 0) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente--;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }
  }


  siguientePag(): void {
    if (this.situacionAsistente < 8) {
      this.arr1[this.situacionAsistente] = false;
      this.situacionAsistente++;
      this.arr1[this.situacionAsistente] = true;
      document.querySelector('#top').scrollIntoView();
    }

  }


  buscarPaginaPrimerError() {
    var page = -1;
    var cuenta = 0;
    if (//parte 1
      this.modelo030Form.controls['idInput'].errors ||
      this.modelo030Form.controls['nombre'].errors ||
      this.modelo030Form.controls['apellidos'].errors
    ) { page = 0; cuenta++; } else if (//parte 3
      (this.modelo030Form.controls['tipoResidente'].errors ||
        this.modelo030Form.controls['nifOtroInteresado'].errors ||
        this.modelo030Form.controls['pasaporteInteresado'].errors ||
        this.modelo030Form.controls['fechaResidenciaFiscal'].errors ||
        this.modelo030Form.controls['nacionalidadInteresado'].errors) && cuenta === 0
    ) { page = 2; cuenta++; }/*  else if (
      (this.modelo030Form.controls['tipoResidenteConyuge'].errors ||
        this.modelo030Form.controls['nifConyuge'].errors ||
        this.modelo030Form.controls['pasaporteConyuge'].errors ||
        this.modelo030Form.controls['fechaResidenciaFiscalConyuge'].errors ||
        this.modelo030Form.controls['nacionalidadConyuge'].errors ||
        this.modelo030Form.controls['genero2'].errors ||
        this.modelo030Form.controls['nifConyuge2'].errors ||
        this.modelo030Form.controls['primerApellidoConyuge'].errors ||
        this.modelo030Form.controls['segundoApellidoConyuge'].errors ||
        this.modelo030Form.controls['nombreConyuge'].errors ||
        this.modelo030Form.controls['fechaNacimientoConyuge'].errors ||
        this.modelo030Form.controls['lugarNacimientoConyuge'].errors ||
        this.modelo030Form.controls['provinciaConyuge'].errors ||
        this.modelo030Form.controls['municipioConyuge'].errors) && cuenta === 0
    ) { page = 3; cuenta++; } */ else if (
      (this.modelo030Form.controls['phone'].errors ||
        this.modelo030Form.controls['email'].errors) && cuenta === 0
    ) { page = 4; } /* else if (
      (this.modelo030Form.controls['tipoVia1'].errors ||
        this.modelo030Form.controls['nombreVia1'].errors ||
        this.modelo030Form.controls['tipoEnumeracion1'].errors ||
        this.modelo030Form.controls['numeroVia1'].errors ||
        this.modelo030Form.controls['calificadorNum1'].errors ||
        this.modelo030Form.controls['bloque1'].errors ||
        this.modelo030Form.controls['portal1'].errors ||
        this.modelo030Form.controls['escalera1'].errors ||
        this.modelo030Form.controls['planta1'].errors ||
        this.modelo030Form.controls['puerta1'].errors ||
        this.modelo030Form.controls['localidad1'].errors ||
        this.modelo030Form.controls['provincia1'].errors ||
        this.modelo030Form.controls['codigoPostal1'].errors ||
        this.modelo030Form.controls['catastral1'].errors ||
        this.modelo030Form.controls['refCatastral1'].errors) && cuenta === 0
    ) { page = 5; }  else if (
      (this.modelo030Form.controls['catastral2'].errors ||
        this.modelo030Form.controls['domicilioExtranjero'].errors ||
        this.modelo030Form.controls['poblacionExtranjero'].errors ||
        this.modelo030Form.controls['codPostalExtranjero'].errors ||
        this.modelo030Form.controls['provinciaExtranjero'].errors ||
        this.modelo030Form.controls['paisExtranjero'].errors) && cuenta === 0
    ) { page = 6; } else if (
      (this.modelo030Form.controls['tipoVia2'].errors ||
        this.modelo030Form.controls['nombreVia2'].errors ||
        this.modelo030Form.controls['tipoEnumeracion2'].errors ||
        this.modelo030Form.controls['numeroVia2'].errors ||
        this.modelo030Form.controls['calificadorNum2'].errors ||
        this.modelo030Form.controls['bloque2'].errors ||
        this.modelo030Form.controls['portal2'].errors ||
        this.modelo030Form.controls['escalera2'].errors ||
        this.modelo030Form.controls['puerta2'].errors ||
        this.modelo030Form.controls['localidad2'].errors ||
        this.modelo030Form.controls['provincia2'].errors ||
        this.modelo030Form.controls['codigoPostal2'].errors ||
        this.modelo030Form.controls['destinatario2'].errors ||
        this.modelo030Form.controls['apartadoCorreos'].errors ||
        this.modelo030Form.controls['localidad3'].errors ||
        this.modelo030Form.controls['codigoPostal3'].errors ||
        this.modelo030Form.controls['provincia3'].errors ||
        this.modelo030Form.controls['destinatario3'].errors) && cuenta === 0
    ) { page = 7; }*/ else if (
      (this.modelo030Form.controls['marital_status'].errors) && cuenta === 0
    ) { page = 8; }
    return page;
  }
}
