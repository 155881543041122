import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';


@Component({
  selector: 'kt-formulario720',
  templateUrl: './formulario720.component.html',
  styleUrls: ['./formulario720.component.scss']
})
export class Formulario720Component implements OnInit {
  loaded: boolean;
  modelo720Form: FormGroup;
  form_errors: boolean;
  form_array_errors: boolean;
  paises: object[];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService) {

    this.form_array_errors = false;
    this.form_errors = false;
    this.loaded = false;
    this.paises = [];
    this.api.willianApi({ modelo: "paises", accion: "listar" }).subscribe(res => {
      res['respuesta'].forEach(element => {
        this.paises.push(element);
      });
    });
    this.modelo720Form = this.fb.group({
      DNI: ["", [Validators.required]],
      nombreORS: ["", [Validators.required]],
      ejercicio: new Date().getFullYear() - 1,
      telefono: "963522587",
      nombreContacto: "Jesús Alberto Pérez Sanz",
      cuentaBancaria: ["", [Validators.required]],
      comentarios: "",
      id_usuario: "",
      tipoPago: [""],
      bienes: new FormArray([])
    });
  }

  ngOnInit() {
    if (this.loginService.devolver()) {
      this.api.willianApi({ modelo: 'usuarios', accion: 'datosPanel', argumentos: localStorage.getItem('id_usuario') }).subscribe(res => {
        console.log(res);
        this.modelo720Form.get('DNI').setValue(res['respuesta']['person_dni']);
        this.modelo720Form.get('nombreORS').setValue(res['respuesta']['person_full_name']);
        this.cdRef.detectChanges();
      });
      this.modelo720Form.get('id_usuario').setValue(localStorage.getItem('id_usuario'));
    }
  }


  guardarFormPrincipal(): void {
    if (this.modelo720Form.valid) {
      this.api.willianApi({ accion: 'guardarModelo720', argumentos: this.modelo720Form.value }).subscribe(res => {
        if (parseInt(res['respuesta']) > 0) {
          if (this.loginService.devolver()) {
            this.router.navigate(['/payment']);
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.setItem('pendiente', res['respuesta']);
        }
      }, error => {
        console.log(error);
      })
    } else {
      this.form_errors = true;
      return;
    }
  }

  anadirBien() {
    for (let control of this.modelo720Form.get('bienes')['controls']) {
      if (control.controls.saved.value == 0) {
        return;
      }
    }
    const group = this.fb.group({
      nifRepresentante: ["", [Validators.required]],
      condicionDeclarante: "",
      claveTipoBien: ["", [Validators.required]],
      subclaveBien: [""],
      codPais: ["", [Validators.required]],
      claveIdentCuenta: [""],
      identValores: [""],
      bic: [""],
      cuenta: [""],
      entidad: [""],
      NIFPais: [""],
      domicilioEntidad: [""],
      datosComplEntidad: [""],
      poblacionEntidad: [""],
      provinciaEntidad: [""],
      codigoPostal: [""],
      codPaisEntidad: ["", [Validators.required]],
      fechaIncorpEntidad: [""],
      origenBien: ["", [Validators.required]],
      fechaExtincion: [""],
      valoracion1: [""],
      valoracion2: [""],
      claveTipo: ["", [Validators.required]],
      porcPart: [""],
      saved: 0
    });

    const bienes = this.modelo720Form.get('bienes') as FormArray;
    bienes.push(group);
    this.modelo720Form.controls['bienes'] = bienes;
    this.cdRef.detectChanges();
  }

  guardarFormBienes(i: number): void {
    const bien = (<FormArray>this.modelo720Form.get('bienes')).at(i);
    if (bien.valid) {
      bien.patchValue({ saved: 1 });
      this.form_array_errors = false;
    } else {
      this.form_array_errors = true;
    }
  }

  editarFormBienes(i: number): void {
    const bien = (<FormArray>this.modelo720Form.get('bienes')).at(i);
    bien.patchValue({ saved: 0 });
  }

  eliminarFormBienes(i: number): void {
    (<FormArray>this.modelo720Form.get('bienes')).removeAt(i);
  }

}
