import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Solicitud } from '../../../../core/auth/_models/solicitud.model';
import { Observable } from 'rxjs';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { SubheaderService } from '../../../../core/_base/layout';

@Component({
	selector: 'kt-solicitud',
	templateUrl: './solicitud.component.html',
	styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit, OnDestroy {
	
	solicitud: Solicitud;
	solicitudId: Observable<number>;
	solicitudForm: FormGroup;

	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	
	constructor(
		private activatedRoute: ActivatedRoute, 
		private router: Router, 
		private fb: FormBuilder, 
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService) {
			this.header.hide();
			this.footer.hide();
		}
		
		ngOnInit() {
			this.activatedRoute.params.subscribe(params => {
				const id = params.id;
				this.solicitud = new Solicitud();
				this.solicitud.clear();
				if(id && id > 0) {
					this.api.willianApi({}).subscribe(res => {						
						this.solicitud = Object.assign(this.solicitud, res['data'][0]);
						console.log(this.solicitud);
						
						this.initUser();
					})     
				} else {
					this.initUser();
				}
			});
		}
		
		initUser() {
			this.createForm();
			if (!this.solicitud.id_relacion_modelo) {
				this.subheaderService.setTitle('Crear solicitud');
				this.subheaderService.setBreadcrumbs([
					{ title: 'Solicitudes', page: `solicitudes` },
					{ title: 'Crear solicitud', page: `solicitudes/new` }
				]);
				return;
			}
			this.subheaderService.setTitle('Edit solicitud');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Solicitudes', page: `solicitudes` },
				{ title: 'Editar solicitud', page: `solicitudes`, queryParams: { id: this.solicitud.id_relacion_modelo } }
			]);
		}
		
		createForm() {
			this.solicitudForm = this.fb.group({
        id: [this.solicitud.id_relacion_modelo, Validators.required],
        idCliente: [this.solicitud.cliente.id_person, Validators.required],
				modelo: [this.solicitud.modelo, Validators.required],
			});

		}
		
		getComponentTitle() {
			let result = 'Crear solicitud';
			if (!this.solicitud || !this.solicitud.id_relacion_modelo) {
				return result;
			}
			
			result = `Editar solicitud - ${this.solicitud.id_relacion_modelo}`;
			return result;
		}

		saveUser() {			
			const newSolicitud = Object.assign(this.solicitud, this.solicitudForm.value);
			delete newSolicitud.accessToken;
			console.log(newSolicitud);
			
			if(newSolicitud.id) {
				this.api.willianApi({}).subscribe(res => {
					console.log(res); // TODO añadir opción para fallo
					if(res) {
						this.router.navigate(['/panel/solicitudes']);
					}
				});
			} else {
				newSolicitud.password = newSolicitud.email;
				this.api.willianApi({}).subscribe(res => {
					console.log(res); // TODO añadir opción para fallo
					if(res) {
						this.router.navigate(['/panel/solicitudes']);
					}
				});
			}
		}

		preview(files) {
			this.loadingFile = true;
			if(files.length === 0) {
				return;
			}
			var mimeType = files[0].type;
			if(mimeType.match(/image\/*/) == null) {
				return;
			}

			var reader = new FileReader();
			//this.solicitudForm['pic'] = files;
			reader.readAsDataURL(files[0]);
			reader.onload = (_event) => {
				this.solicitudForm['pic'] = reader.result;
				this.loadingFile = false;
			}
		}

		updateUrl(event) {
			event.target.src = '../../../../../assets/img/users/default.jpg';
		}

		ngOnDestroy(): void {
			this.header.toggle();
			this.footer.toggle();
		}
		
	}
	