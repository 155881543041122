import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  credenciales_falsas: boolean;
  loginForm: FormGroup
  formulario_pendiente: boolean;
  texto: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private api: ApiService,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService) {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    this.credenciales_falsas = false;
    this.formulario_pendiente = false;
    this.texto = '';
  }

  ngOnInit() {
    if (this.loginService.devolver()) {
      this.router.navigate(['user-panel']);
    } else if (localStorage.getItem('pendiente') !== null) {
      this.formulario_pendiente = true;
    }
  }

  checkUser() {
    this.api.willianApi({ modelo: "usuarios", accion: 'LogIn', argumentos: this.loginForm.value }).subscribe(res => {
      console.log(res)
      if (res['respuesta']['respuesta']) {
        console.log(res['respuesta']['respuesta']);
        localStorage.setItem('id_usuario', res['respuesta']['id_usuario']);
        localStorage.setItem('email', res['respuesta']['usuario_email']);
        localStorage.setItem('nombre', res['respuesta']['nombre']);
        this.loginService.setTrue();
        this.loginService.setName(res['respuesta']['nombre']);
        this.setUserRelationship();
        if (this.formulario_pendiente) {
          this.router.navigate(['/payment']);
        } else {
          this.router.navigate(['/user-panel']);
        }

      } else {
        this.texto = res['respuesta']['texto'];
        console.log('credenciales falsas');
        this.credenciales_falsas = true;
        this.loginService.setFalse();
        this.cdRef.detectChanges();
      }
    }, error => {
      console.log(error);
    });
  }

  setUserRelationship() {
    this.api.willianApi({
      modelo: "relaciones_modelos",
      accion: "RelacionarUsuarioModelo",
      argumentos: {
        id_usuario: localStorage.getItem('id_usuario'),
        id_relacion_usuario: localStorage.getItem('pendiente')
      }
    }).subscribe(res => {
      console.log(res);
    });
  }
}