// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { MainComponent } from './pages/main/main.component';
import { PreciosComponent } from './pages/precios/precios.component';
import { UsuariosComponent } from './views/pages/usuarios/usuarios.component';
import { UsuarioComponent } from './views/pages/usuarios/usuario/usuario.component';
import { ClientesComponent } from './views/pages/clientes/clientes.component';
import { ClienteComponent } from './views/pages/clientes/cliente/cliente.component';
import { SolicitudesComponent } from './views/pages/solicitudes/solicitudes.component';
import { SolicitudComponent } from './views/pages/solicitudes/solicitud/solicitud.component';
import { DocumentosComponent } from './views/pages/documentos/documentos.component';
import { DocumentoComponent } from './views/pages/documentos/documento/documento.component';
import { FormularioIrpfComponent } from './pages/formularios/formulario-irpf/formulario-irpf.component';
import { Formulario720Component } from './pages/formularios/formulario720/formulario720.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { LoginComponent } from './pages/login/login.component';
import { PagoRealizadoComponent } from './pages/pago-realizado/pago-realizado.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { ContactComponent } from './pages/contact/contact.component';
import { Formulario210Component } from './pages/formularios/formulario210/formulario210.component';
import { Formulario100Component } from './pages/formularios/formulario100/formulario100.component';
import { Formulario714Component } from './pages/formularios/formulario714/formulario714.component';
import { Formulario030Component } from './pages/formularios/formulario030/formulario030.component';
import { PanelUsuarioComponent } from './pages/panel-usuario/panel-usuario.component';
import { BeckhamLawComponent } from './pages/formularios/beckham-law/beckham-law.component';
import { FreelanceComponent } from './pages/formularios/freelance/freelance.component';
import { SpecialTaxComponent } from './pages/formularios/special-tax/special-tax.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { RecuperarPasswordComponent } from './pages/recuperar-password/recuperar-password.component';
import { CambiarPasswordComponent } from './pages/cambiar-password/cambiar-password.component';

import { RecomendacionComponent } from './pages/recomendacion/recomendacion.component';
import { PagoComponent } from './pages/pago/pago.component';
import { PagoFallidoComponent } from './pages/pago/pago-fallido/pago-fallido.component';
import { FacturaComponent } from './pages/factura/factura.component';
import { UpdateUserComponent } from './pages/panel-usuario/update-user/update-user.component';
import { ThanksComponent } from "./pages/thanks/thanks.component";
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { LegalNoticesComponent } from './pages/legal-notices/legal-notices.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ActivarUsuarioComponent } from './pages/activar-usuario/activar-usuario.component';


const routes: Routes = [
	{ path: 'main', component: MainComponent },
	{ path: 'pricing', component: PreciosComponent },
	{
		path: 'form', children: [
			{ path: '720', component: Formulario720Component },
			{ path: 'IRPF', component: FormularioIrpfComponent },
			{ path: '210', component: Formulario210Component },
			{ path: '100', component: Formulario100Component },
			{ path: '714', component: Formulario714Component },
			{ path: '030', component: Formulario030Component },
			{ path: 'Beckham-Law', component: BeckhamLawComponent },
			{ path: 'freelance', component: FreelanceComponent },
			{ path: 'special-tax', component: SpecialTaxComponent }
		]
	},
	{ path: 'faqs', component: FaqsComponent },
	{ path: 'user-panel', component: PanelUsuarioComponent },
	{ path: 'update-user', component: UpdateUserComponent },
	{ path: 'activate-user', component: ActivarUsuarioComponent },
	{ path: 'thank-you', component: ThanksComponent },
	{ path: 'forgot-password', component: RecuperarPasswordComponent },
	{ path: 'change-password', component: CambiarPasswordComponent },
	{ path: 'recommend', component: RecomendacionComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'invoice/:id', component: FacturaComponent },
	{ path: 'payment-done', component: PagoRealizadoComponent },
	{ path: 'payment-failed', component: PagoFallidoComponent },
	{ path: 'register', component: RegistroComponent },
	{ path: 'reviews', component: ReviewsComponent },
	{ path: 'contact', component: ContactComponent },
	
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'terms-conditions', component: TermsConditionsComponent },
	{ path: 'legal-notices', component: LegalNoticesComponent },
	{ path: 'disclaimer', component: DisclaimerComponent },

	{ path: 'payment', component: PagoComponent },
	{ path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
	{
		path: 'panel',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'solicitudes', component: SolicitudesComponent },
			{ path: 'usuarios', component: UsuariosComponent },
			{ path: 'usuarios/:id', component: UsuarioComponent },
			{ path: 'usuarios/new', component: UsuarioComponent },
			{ path: 'clientes', component: ClientesComponent },
			{ path: 'clientes/:id', component: ClienteComponent },
			{ path: 'clientes/new', component: ClienteComponent },
			{ path: 'solicitudes', component: SolicitudesComponent },
			{ path: 'solicitudes/:id', component: SolicitudComponent },
			{ path: 'solicitudes/new', component: SolicitudComponent },
			{ path: 'documentos', component: DocumentosComponent },
			{ path: 'documentos/:id', component: DocumentoComponent },
			{ path: 'documentos/new', component: DocumentoComponent },
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'usuarios', pathMatch: 'full' },
			{ path: '**', redirectTo: 'usuarios', pathMatch: 'full' }
		],
	},
	//{ path: '*', component: MainComponent },
	{ path: '', component: MainComponent, pathMatch: "full" },

	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' }

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
