// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Client } from '../../../core/auth/_models/client.model';
import { SubheaderService } from '../../../core/_base/layout';
// RXJS
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteClienteComponent } from './cliente/delete-cliente/delete-cliente.component';

@Component({
  selector: 'kt-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})

export class ClientesComponent implements OnInit {

  clientes: Client[] = [];
  dataSource;
  displayedColumns: string[] = ['select', 'id', 'username', 'email', 'opciones'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Client>(true, []);

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private footer: FooterService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {

    this.subheaderService.setTitle('Clientes');

    this.api.apiPanel({ modelo: 'persons', accion: 'ListarClientes' }).subscribe(res => {
      if (res['token_status']) {
        console.log(res['respuesta'])
        for (let o of res['respuesta']) {
          this.clientes.push(o);
        }
        this.dataSource = new MatTableDataSource(this.clientes);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        localStorage.clear();
        this.router.navigate(['auth/login']);
      }

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientes.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.clientes.length) {
      this.selection.clear();
    } else {
      this.clientes.forEach(row => this.selection.select(row));
    }
  }

  fetchClients() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.person_full_name}`,
      });
    });
    console.log(messages);

  }

  editClient(id: number) {
    this.router.navigate(['../clientes/', id], { relativeTo: this.activatedRoute });
  }

  deleteClient(id: number) {
    let client = this.clientes.find(i => i.id_person === id);
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      maxWidth: '400px',
      data: {
        id: client.id_person,
        name: client.person_full_name
      }
    }
    const dialogRef = this.matDialog.open(DeleteClienteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.api.willianApi({ modelo: 'clientes', accion: 'eliminar', argumentos: id }).subscribe(res => {
          if (res) {
            this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
          }
        });
      }
    });
  }

  recargarClientes() {
    this.api.apiPanel({ modelo: 'persons', accion: 'listarClientes' }).subscribe(res => {
      this.clientes = [];
      for (let o of res['respuesta']) {
        this.clientes.push(o);
      }
      this.dataSource = new MatTableDataSource(this.clientes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
